import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FormProvider, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { RouteComponentProps } from "react-router-dom";

import CompanyBasicForm from "../../../CustomerPages/tabs/ProfileTab/components/SlidesCompanyForm/CompanyBasicForm";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import { ICompanyModel } from "../../../../models/company.model";
import { dirtyFormValues } from "../../../../utils/getDirtyFormValues";
import { EditCompanyBasicSchema } from "../../../CustomerPages/tabs/ProfileTab/validation-schemas/company/edit-company-basic.schema";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import {Helmet} from "react-helmet";

const EditBasic: React.FC<RouteComponentProps> = observer(({ history }) => {
  const {
    auth: { userDecoded, setUserDecoded },
  } = useRootStore();

  const methods = useForm({ resolver: yupResolver(EditCompanyBasicSchema) });
  const { formState } = methods;

  const [loadingCurrentData, setLoadingCurrentData] = useState(true);
  useEffect(() => {
    httpClient
      .get(
        `/companies/${userDecoded!.sub}?${RequestQueryBuilder.create({
          fields: [
            "id",
            "name",
            "email",
            "cnpj",
            "phone",
            "about",
            "facebookUrl",
            "instagram",
            "website",
            "logo",
            "banner",
          ],
        }).query()}`
      )
      .then((res) => {
        Object.keys(res.data).forEach((key) => {
          if (res.data[key]) {
            methods.register(key);
            methods.setValue(key, res.data[key], { shouldDirty: false });
          }
        });
      })
      .finally(() => {
        setLoadingCurrentData(false);
      });
  }, [userDecoded]);

  const onSubmit = (formValues: ICompanyModel) => {
    const dirtyValues: any = dirtyFormValues(formState.dirtyFields, formValues);
    if (Object.keys(dirtyValues).length > 0)
      httpClient
        .patch(
          `/companies/${userDecoded!.sub}`,
          Object.entries(dirtyValues).reduce(
            (formData, [currentKey, currentValue]: [string, any]) => {
              formData.append(currentKey, currentValue);

              return formData;
            },
            new FormData()
          )
        )
        .then((res) => {
          const { logo, email, name } = res.data!;
          if (logo) setUserDecoded({ ...userDecoded, logo });
          if (email) setUserDecoded({ ...userDecoded, email });
          if (name) setUserDecoded({ ...userDecoded, name });

          history.replace("/config");
        });
  };

  const { isDirty } = formState;

  return (
    <IonPage>
      <IonHeader>
        <Helmet title={'Editar Cadastro'}/>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonBackButton defaultHref={"/config"} />
          </IonButtons>
          <IonTitle>Dados da empresa</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WrapperWithFooter>
          <Container>
            <div style={{ padding: 24 }}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {loadingCurrentData ? (
                    <IonProgressBar type="indeterminate" />
                  ) : (
                    <CompanyBasicForm disableCNPJ={true} />
                  )}
                  <IonButton
                    disabled={!isDirty}
                    className="ion-margin-top"
                    expand={"block"}
                    type={"submit"}
                  >
                    Salvar
                  </IonButton>
                </form>
              </FormProvider>
            </div>
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default EditBasic;
