import React, { useEffect } from "react";
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonPage,
  IonContent,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory, useParams } from "react-router-dom";

import TagListFilter from "../components/TagListFilter";
import { Helmet } from "react-helmet";
import WrapperWithFooter from "../../../../../../components/WrapperWithFooter";
import Container from "../../../../../../components/Container";
import { usePlatform } from "../../../../../../utils/usePlatform";
import { FilterState } from "../../HomeRoutes";

const SelectTagStep: React.FC<{
  setFilter: (filter: any) => void;
  filter: FilterState;
}> = ({ setFilter, filter }) => {
  const history = useHistory();
  const params = useParams<{ discount: string }>();
  useEffect(() => {
    setFilter({ ...filter, discount: +params.discount / 100 });
  }, [params]);

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Início"} />
        <WrapperWithFooter>
          <Container>
            <IonToolbar>
              <IonButtons slot={"start"}>
                <IonButton
                  onClick={() => {
                    setFilter({ ...filter, discount: undefined });
                    history.push(`/empresas`);
                  }}
                >
                  <IonIcon icon={arrowBack} slot={"start"} />
                  Descontos
                </IonButton>
              </IonButtons>
              <IonTitle>Escolher Categoria</IonTitle>
            </IonToolbar>
            <TagListFilter
              setTag={(tag: string) => {
                setFilter({ ...filter, tag });
                history.push(
                  `/empresas/desconto/${params.discount}/tag/${tag}`
                );
              }}
            />
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
};

export default SelectTagStep;
