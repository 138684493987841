import React from "react";
import { useMediaQuery } from "react-responsive";

import Footer from "./Footer";

const WrapperWithFooter: React.FC = ({ children }) => {
  const isTabletOrUp = useMediaQuery({
    query: "(min-width: 768px)",
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flex: 1 }}>{children}</div>
      {isTabletOrUp && (
        <div style={{ flex: 0 }}>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default WrapperWithFooter;
