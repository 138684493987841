import React from "react";
import { observer } from "mobx-react-lite";
import { IonAvatar, IonRow, IonText, IonIcon } from "@ionic/react";
import { person } from "ionicons/icons";
import styled from "styled-components";

export interface ProfilePictureProps {
  image?: string | File | null;
  name: string | null;
  onClick?: () => void;
}

const AvatarLarge = styled(IonAvatar)`
  width: 64px;
  height: 64px;
  background-color: var(--ion-color-secondary);
  color: white;
  display: grid;
  place-items: center;

  img {
    width: 64px;
    height: 64px;
  }
`;

export const ProfileCard: React.FC<ProfilePictureProps> = observer(
  ({ image, name, onClick }) => {
    return (
      <IonRow
        className={"ion-align-items-center"}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "normal", marginBottom: 8 }}
      >
        <AvatarLarge>
          {image ? (
            <img
              src={
                typeof image === "string" ? image : URL.createObjectURL(image)
              }
              alt=""
            />
          ) : (
            <IonIcon size={"large"} icon={person} />
          )}
        </AvatarLarge>
        <IonText className={"ion-margin-start"} color={"secondary"}>
          <p>{name}</p>
        </IonText>
      </IonRow>
    );
  }
);

export default ProfileCard;
