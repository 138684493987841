import React from "react";
import { IonContent, IonLabel, IonPage, IonText } from "@ionic/react";
import { useHistory } from "react-router-dom";

import DiscountFilter from "../components/DiscountFilter";
import { usePlatform } from "../../../../../../utils/usePlatform";
import { Helmet } from "react-helmet";
import WrapperWithFooter from "../../../../../../components/WrapperWithFooter";
import Container from "../../../../../../components/Container";
import { FilterState } from "../../HomeRoutes";

const SelectDiscountStep: React.FC<{
  setFilter: (filter: any) => void;
  filter: FilterState;
}> = ({ setFilter, filter }) => {
  const history = useHistory();
  const platform = usePlatform();
  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Início"} />
        <WrapperWithFooter>
          <Container>
            <div style={{ display: "grid", placeItems: "center" }}>
              <IonLabel
                style={{ marginTop: 16, marginBottom: 32 }}
                className="ion-hide-md-up"
              >
                <img
                  width={32}
                  style={{ marginBottom: -8 }}
                  src="/assets/images/LogotipoBeauticket.png"
                  alt=""
                />
                <IonText color={"secondary"}>
                  <p
                    style={{
                      display: "inline-block",
                      fontWeight: "bold",
                      fontFamily: "Vogue",
                      fontSize: 20,
                    }}
                  >
                    EAUTICKET
                  </p>
                </IonText>
              </IonLabel>
              <IonText color="secondary">
                <h1>Escolher Desconto</h1>
              </IonText>
            </div>
            <DiscountFilter
              setDiscount={(discount) => {
                setFilter({ ...filter, discount });
                history.push(
                  `/empresas/desconto/${Math.round(discount * 100)}`
                );
              }}
            />
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
};

export default SelectDiscountStep;
