import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonSearchbar,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import CompaniesList from "../components/CompaniesList";
import InfiniteScroll from "../../../../../../components/InfiniteScroll";
import useCompanies from "../useCompanies";
import { usePlatform } from "../../../../../../utils/usePlatform";
import WrapperWithFooter from "../../../../../../components/WrapperWithFooter";
import { FilterState } from "../../HomeRoutes";
import Container from "../../../../../../components/Container";

export const SearchBar = styled(IonSearchbar)`
  margin-top: 16px;
  --box-shadow: 0px 0px 2px var(--ion-color-primary) !important;
`;

const CompanyListStep: React.FC<{
  filter: FilterState;
  setFilter: (filter: any) => void;
}> = ({ setFilter, filter }) => {
  const history = useHistory();
  const params = useParams<{ discount: string; tag: string }>();
  useEffect(() => {
    const { tag, discount } = params;
    setFilter({ ...filter, tag, discount: +discount / 100 });
  }, [params]);

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
    if (infiniteScrollEl?.current) infiniteScrollEl.current.disabled = false;
  }, [filter]);

  const infiniteScrollEl = useRef<HTMLIonInfiniteScrollElement>(null);
  const { companiesStore: companies } = useCompanies({
    infiniteScrollEl: infiniteScrollEl?.current,
    filter,
    page,
    select: ["id", "logo", "about", "rating", "name"],
  });

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Início"} />
        <WrapperWithFooter>
          <Container>
            {companies ? (
              <>
                <IonToolbar>
                  <IonButtons slot={"start"}>
                    <IonButton
                      onClick={() => {
                        setFilter({ ...filter, tag: undefined });
                        history.push(`/empresas/desconto/${params.discount}`);
                      }}
                    >
                      <IonIcon icon={arrowBack} slot={"start"} />
                      Categorias
                    </IonButton>
                  </IonButtons>
                  <SearchBar
                    mode="ios"
                    placeholder="Buscar"
                    color="white"
                    value={filter.searchText}
                    onIonClear={(e) => setFilter({ ...filter, searchText: "" })}
                    onIonChange={(e) =>
                      setFilter({ ...filter, searchText: e.detail.value! })
                    }
                  />
                </IonToolbar>
                <CompaniesList companies={companies} />
                <InfiniteScroll
                  infiniteScrollEl={infiniteScrollEl}
                  nextPage={() => setPage(page + 1)}
                />
              </>
            ) : (
              <IonProgressBar type="indeterminate" />
            )}
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
};

export default CompanyListStep;
