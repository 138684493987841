import * as yup from "yup";

const service = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  price: yup.number().min(0, "Valor minimo 0").required("Preço obrigatório"),
});

export const CompanyOperationsSchema = yup.object().shape({
  opensAt: yup
    .string()
    .required("Data de abertura obrigatória")
    .matches(/\d\d:\d\d/, "Data inválida"),
  closesAt: yup
    .string()
    .required("Data de fechamento obrigatória")
    .matches(/\d\d:\d\d/)
    .test(
      "is-after-opensAt",
      "Data de fechamento inválido",
      (value, context) => {
        return value! > context.parent.opensAt;
      }
    ),
  tags: yup.array().min(1, "Deve ter no minimo uma tag"),
  services: yup.array().min(1, "Cadastre pelo menos um serviço").of(service),
});
