import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { observer } from "mobx-react-lite";

import { httpClient } from "../../../../utils/http-client";
import {
  CardCollection,
  ICardCollection,
  ICardModel,
} from "../../../../models/card.model";
import CardsList from "./components/CardsList";
import NewCardForm from "./components/NewCardForm";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import Container from "../../../../components/Container";
import {Helmet} from "react-helmet";

const Cards: React.FC = observer(() => {
  const {
    auth: { userDecoded },
  } = useRootStore();
  const [cards, setCards] = useState<ICardCollection | null>(null);
  useEffect(() => {
    httpClient
      .get(
        `/cards?${RequestQueryBuilder.create({
          join: ["customer", ["id"]],
          filter: [
            {
              field: "customer.id",
              operator: "$eq",
              value: userDecoded!.sub,
            },
          ],
        }).query()}`
      )
      .then(({ data }: { data: ICardModel[] }) =>
        setCards(
          CardCollection.create({
            collection: data.map(({ brand, securityCode, lastDigits, id }) => ({
              brand,
              securityCode,
              lastDigits,
              id,
            })),
          })
        )
      );
  }, [userDecoded]);

  return (
    <IonPage>
      <IonHeader>
          <Helmet title={"Cartões"} />
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonBackButton defaultHref={"/perfil"} />
          </IonButtons>
          <IonTitle>Cartões</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Container>
          <div style={{ padding: 24 }}>
            <NewCardForm cards={cards} />
            <CardsList cards={cards} />
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
});

export default Cards;
