import React from "react";
import { observer } from "mobx-react-lite";
import { IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import styled from "styled-components";

import { ICompanyModel } from "../../../../../../models/company.model";
import RatingStar from "../../../../../../components/RatingStar";
import { Discount } from "../../../../../../models/service.model";
import { ISlotModel } from "../../../../../../models/slot.model";

export interface CompaniesListProps {
  companies: ICompanyModel[];
}

const CompanyAvatar = styled.img`
  margin-right: 16px;
  width: 130px;
  height: 79px;
`;

const CompaniesList: React.FC<CompaniesListProps> = observer(
  ({ companies }) => (
    <IonList>
      {companies.length > 0 ? (
        companies.map((company) => {
          const [halfDiscountCount, fullDiscountCount] = company.services
            ? company.services.reduce(
                ([halfDiscount, fullDiscount], service: any) => [
                  halfDiscount +
                    service.slots
                      .filter(
                        (slot: ISlotModel) => slot.discount == Discount.HALF
                      )
                      .reduce(
                        (total: number, slot: ISlotModel) =>
                          total + slot.availableSlots,
                        0
                      ),
                  fullDiscount +
                    service.slots
                      .filter(
                        (slot: ISlotModel) => slot.discount == Discount.FULL
                      )
                      .reduce(
                        (total: number, slot: ISlotModel) =>
                          total + slot.availableSlots,
                        0
                      ),
                ],
                [0, 0]
              )
            : [0, 0];

          return (
            <IonItem key={company.id} routerLink={"/empresas/" + company.id}>
              <CompanyAvatar src={company.logo} alt="" />
              <IonLabel>
                <IonText color="secondary">
                  <h2 style={{ fontWeight: "bold" }}>
                    {company.name} <RatingStar rating={company.rating!} />
                  </h2>
                  <p className="ion-text-wrap ion-text-justify">
                    {company.about}
                  </p>
                </IonText>
                {fullDiscountCount! > 0 && (
                  <p
                    style={{
                      display: "inline-block",
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: "var(--ion-color-primary)",
                      border: "1px solid var(--ion-color-primary)",
                      padding: "2px 4px",
                      borderRadius: 5,
                      marginTop: 8,
                      marginRight: 8,
                    }}
                  >
                    {fullDiscountCount} TICKET
                    {fullDiscountCount! > 1 && "S"} 100%
                  </p>
                )}
                {halfDiscountCount! > 0 && (
                  <p
                    style={{
                      display: "inline-block",
                      fontWeight: "bold",
                      color: "var(--ion-color-primary)",
                      border: "1px solid var(--ion-color-primary)",
                      padding: "2px 4px",
                      marginTop: 8,
                      borderRadius: 5,
                    }}
                  >
                    {halfDiscountCount} TICKET
                    {halfDiscountCount! > 1 && "S"} 50%
                  </p>
                )}
              </IonLabel>
            </IonItem>
          );
        })
      ) : (
        <p className="ion-margin">Não foram encontrados empresas</p>
      )}
    </IonList>
  )
);

export default CompaniesList;
