import {
  IonButton,
  IonInput,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonNote,
  IonText,
} from "@ionic/react";
import IonItemOutlined from "../../../../../components/IonItemOutlined";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CardSchema } from "../validation-schemas/card.schema";
import throttle from "lodash.throttle";
import creditCardType from "credit-card-type";
import { observer } from "mobx-react-lite";

import { httpClient } from "../../../../../utils/http-client";
import { prettyCreditCard } from "../../../../../utils/prettyCreditCard";
import { ICardCollection, ICardModel } from "../../../../../models/card.model";
import TextField from "../../../../../components/TextField";

interface CardForm {
  brand: string;
  cardNumber: string;
  securityCode: string;
  holder: string;
  expirationDate: string;
}

interface NewCardFormProps {
  cards?: ICardCollection | null;
  closeModal?: () => void;
  selectCard?: (card: ICardModel) => void;
}

const NewCardForm: React.FC<NewCardFormProps> = observer(
  ({ cards, selectCard, closeModal }) => {
    const methods = useForm<CardForm>({
      resolver: yupResolver(CardSchema),
    });

    const {
      register,
      watch,
      errors,
      handleSubmit,
      setValue,
      reset: resetForm,
    } = methods;

    useEffect(() => {
      register("cardNumber");
      register("brand");
    }, []);

    const watchedCardNumber = watch("cardNumber");
    const checkBrandAndFormat = useCallback(
      throttle((cardNumber: string) => {
        if (!cardNumber) {
          setValue("brand", "");
          return;
        }

        const sanitized = cardNumber.replace(/\s/g, "");
        const cardsParsed = creditCardType(sanitized);
        const brand = cardsParsed[0];

        let brandName = brand?.niceType;
        if (brand?.niceType === "Mastercard") brandName = "Master";
        if (brand?.niceType === "American Express") brandName = "Amex";
        if (brand) {
          setValue("brand", brandName);

          setValue("cardNumber", prettyCreditCard(sanitized, brand), {});
        } else {
          setValue("brand", "");
        }
      }, 400),
      []
    );

    useEffect(() => checkBrandAndFormat(watchedCardNumber), [
      checkBrandAndFormat,
      watchedCardNumber,
    ]);

    const [showLoading, setShowLoading] = useState(false);
    const onSubmit = (formData: CardForm) => {
      setShowLoading(true);
      httpClient
        .post(`/cards`, {
          ...formData,
          cardNumber: formData.cardNumber.replace(/\D/g, ""),
        })
        .then((res) => {
          resetForm();
          if (cards) cards.addCard(res.data);
          if (selectCard) selectCard(res.data);
          if (closeModal) closeModal();
        })
        .finally(() => setShowLoading(false));
    };

    return (
      <>
        <IonListHeader>
          <IonLabel>Novo Cartão</IonLabel>
        </IonListHeader>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonItemOutlined>
              <IonLabel position={"floating"}>Número do cartão</IonLabel>
              <IonInput
                value={watchedCardNumber}
                onIonChange={(e) => setValue("cardNumber", e.detail!.value)}
                type={"text"}
                name="cardNumber"
                inputmode="numeric"
              />
              <IonNote slot={"end"}>{watch("brand")}</IonNote>
            </IonItemOutlined>
            {(errors?.cardNumber || errors.brand) && (
              <IonText color="danger">
                <p>Cartão inválido</p>
              </IonText>
            )}

            <TextField name="holder" label="Nome no cartão" />

            <TextField
              name="expirationDate"
              label="Data de vencimento"
              unmask={false}
              inputMode={"numeric"}
              mask={"99/9999"}
            />

            <TextField
              name="securityCode"
              minLength={3}
              maxLength={4}
              label="Código de segurança"
            />

            <IonButton expand={"full"} type={"submit"}>
              Criar
            </IonButton>
            {closeModal && (
              <IonButton
                expand={"full"}
                onClick={() => {
                  resetForm();
                  closeModal();
                }}
                fill={"clear"}
              >
                Cancelar
              </IonButton>
            )}
          </form>
        </FormProvider>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Salvando..."}
        />
      </>
    );
  }
);

export default NewCardForm;
