import React, { useEffect } from "react";
import { IonLabel, IonText, IonSelect, IonSelectOption } from "@ionic/react";

import IonItemOutlined from "./IonItemOutlined";
import { useFormContext } from "react-hook-form";

export interface SelectOptions {
  value: string | number;
  label: string;
}

export interface TextAreaProps {
  label: string;
  name: string;
  options: SelectOptions[];
}

const TextArea: React.FC<TextAreaProps> = ({ options, name, label }) => {
  const { register, errors, setValue, watch } = useFormContext();
  useEffect(() => {
    register(name);
  }, []);

  return (
    <>
      <IonItemOutlined>
        <IonLabel position={"floating"}>{label}</IonLabel>
        <IonSelect
          name={name}
          value={watch(name)}
          cancelText={"Cancelar"}
          onIonChange={(e) => setValue(name, e.detail.value)}
          placeholder="Selecione"
        >
          {options.map((option) => (
            <IonSelectOption value={option.value} key={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItemOutlined>

      {errors && errors[name] && (
        <IonText color={"danger"}>
          <p>{errors[name]?.message}</p>
        </IonText>
      )}
    </>
  );
};

export default TextArea;
