import { CreditCardType } from "credit-card-type/dist/types";

export function prettyCreditCard(cardNumber: string, brand: CreditCardType) {
  // @ts-ignore
  let offsets = [].concat(0, brand.gaps, cardNumber.length);
  let components = [];

  for (let i = 0; offsets[i] < cardNumber.length; i++) {
    let start = offsets[i];
    let end = Math.min(offsets[i + 1], cardNumber.length);
    components.push(cardNumber.substring(start, end));
  }

  return components.join(" ");
}
