import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonSlides,
  IonSlide,
  IonRow,
  IonToast,
  IonLoading,
  IonBackButton,
} from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import { v4 } from "uuid";

import CompanyBasicForm from "./components/SlidesCompanyForm/CompanyBasicForm";
import CompanyAddressForm from "./components/SlidesCompanyForm/CompanyAddressForm";
import CompanyOperationForm from "./components/SlidesCompanyForm/CompanyOperationForm";
import { CompanyBasicSchema } from "./validation-schemas/company/company-basic.schema";
import { CompanyAddressSchema } from "./validation-schemas/company/company-address.schema";
import { CompanyOperationsSchema } from "./validation-schemas/company/company-operations.schema";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import {Helmet} from "react-helmet";

const Slides = styled(IonSlides)`
  min-height: calc(100vh - 113px);
`;

const Slide = styled(IonSlide)`
  padding: 34px;
`;

const CompanyCreateForm: React.FC<RouteComponentProps> = observer(
  ({ history }) => {
    const {
      auth: { setAccessToken },
    } = useRootStore();
    const uuid = v4();
    const methods = useForm({
      defaultValues: {
        tags: [],
        amenities: [],
        id: uuid,
        address: { companyId: uuid },
      },
    });
    const {
      formState: { dirtyFields },
    } = methods;

    const slidesEl = useRef<HTMLIonSlidesElement | null>(null);
    useEffect(() => {
      slidesEl?.current?.lockSwipeToNext(true);
    }, [slidesEl]);

    const backStep = async () => {
      const isBeginning = await slidesEl!.current!.isBeginning();
      if (isBeginning) {
        history.replace("/perfil/entrar");
      } else {
        slidesEl!.current!.slidePrev();
      }
    };

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [errorsMessage, setErrorsMessage] = useState("");
    const nextStep = async (currentStep: number) => {
      const values: any = methods.getValues();
      try {
        switch (currentStep) {
          case 0: {
            CompanyBasicSchema.validateSync(values);
            break;
          }
          case 1: {
            CompanyAddressSchema.validateSync(values.address);
            break;
          }
          case 2: {
            CompanyOperationsSchema.validateSync(values);
            break;
          }
        }

        const isEnd = await slidesEl!.current!.isEnd();

        if (isEnd) {
          setLoading(true);
          httpClient
            .post(
              "/companies",
              Object.entries(values).reduce(
                (formData, [currentKey, currentValue]: [string, any]) => {
                  if (!currentValue) return formData;

                  if (
                    typeof currentValue === "string" ||
                    currentValue instanceof Blob
                  ) {
                    formData.append(currentKey, currentValue);
                  } else if (Array.isArray(currentValue)) {
                    currentValue.forEach((innerValue, index) => {
                      Object.keys(innerValue).forEach((innerKey) => {
                        formData.append(
                          `${currentKey}[${index}][${innerKey}]`,
                          innerValue[innerKey]
                        );
                      });
                    });
                  } else if (typeof currentValue === "object") {
                    Object.keys(currentValue).forEach((innerKey) => {
                      formData.append(
                        `${currentKey}[${innerKey}]`,
                        currentValue[innerKey]
                      );
                    });
                  }
                  return formData;
                },
                new FormData()
              )
            )
            .then((res) => {
              methods.reset();
              setAccessToken(res.data.accessToken).then(() =>
                history.replace("/")
              );
            })
            .finally(() => setLoading(false));
        } else {
          slidesEl!.current!.lockSwipeToNext(false);
          slidesEl!.current!.slideNext();
          slidesEl!.current!.lockSwipeToNext(true);
          contentRef!.current!.scrollToTop();
        }
      } catch ({ errors }) {
        setErrorsMessage(errors.join(" "));
      }
    };

    return (
      <IonPage>
        <IonHeader>
          <Helmet title={"Cadastrar Empresa"} />
          <IonToolbar>
            <IonButtons slot={"start"}>
              <IonBackButton defaultHref={"/boas-vindas"} />
            </IonButtons>
            <IonTitle>Cadastrar empresa</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={contentRef}>
          <WrapperWithFooter>
            <IonToast
              color={"danger"}
              position={"top"}
              isOpen={!!errorsMessage}
              onDidDismiss={() => setErrorsMessage("")}
              message={errorsMessage}
              duration={1500}
            />
            <Container>
              <FormProvider {...methods}>
                <form onSubmit={(e) => e.preventDefault()} ref={formRef}>
                  <Slides pager ref={slidesEl}>
                    <Slide>
                      <CompanyBasicForm>
                        <IonButton
                          className={"ion-margin"}
                          fill={"clear"}
                          onClick={() => nextStep(0)}
                        >
                          Avançar
                        </IonButton>
                      </CompanyBasicForm>
                    </Slide>
                    <Slide>
                      <CompanyAddressForm>
                        <IonRow className={"ion-justify-content-between"}>
                          <IonButton
                            fill={"clear"}
                            className={"ion-margin"}
                            onClick={backStep}
                          >
                            Retornar
                          </IonButton>
                          <IonButton
                            fill={"outline"}
                            className={"ion-margin"}
                            onClick={() => nextStep(1)}
                          >
                            Avançar
                          </IonButton>
                        </IonRow>
                      </CompanyAddressForm>
                    </Slide>
                    <Slide>
                      <CompanyOperationForm>
                        <p>
                          Ao cadastrar você aceita os{" "}
                          <a
                            target="_blank"
                            href={`${process.env.REACT_APP_API_URL}/politica_de_privacidade.pdf`}
                          >
                            termos de uso e política de privacidade
                          </a>
                        </p>
                        <IonRow className={"ion-justify-content-between"}>
                          <IonButton
                            fill={"clear"}
                            className={"ion-margin"}
                            onClick={backStep}
                          >
                            Retornar
                          </IonButton>
                          <IonButton
                            className={"ion-margin"}
                            onClick={() => nextStep(2)}
                          >
                            Salvar
                          </IonButton>
                        </IonRow>
                      </CompanyOperationForm>
                    </Slide>
                  </Slides>
                </form>
                <IonLoading isOpen={loading} message={"Salvando..."} />
              </FormProvider>
            </Container>
          </WrapperWithFooter>
        </IonContent>
      </IonPage>
    );
  }
);

export default CompanyCreateForm;
