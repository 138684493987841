import React, { useMemo, useState } from "react";
import DayPicker, { DayModifiers } from "react-day-picker";
import styled from "styled-components";

const LegendBoxItem = styled.hr`
  margin-left: 16px;
  margin-right: 8px;
  margin-bottom: 2px;
  display: inline-block;
  border-top: solid 4px ${(props: { borderColor: string }) => props.borderColor} !important;
  width: 37px;
`;

export const Container = styled.div`
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;

  @media (min-width: 768px) {
    margin: 0 32px;
  }

  .DayPicker-Day--outside {
    border: 0 !important;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    background: #cc2727;
    border-radius: 10px;
  }

  .DayPicker-NavButton {
    color: #181716 !important;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
    background: url("/assets/images/left-arrow.png") no-repeat;
    background-size: contain;
  }

  .DayPicker-NavButton--next {
    background: url("/assets/images/right-arrow.png") no-repeat;
    background-size: contain;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    border: 1px solid #707070;
  }

  .DayPicker-Caption {
    margin-bottom: 1em;
    padding: 0 1em;
    color: #f4ede8;

    > div {
      text-align: center;
    }
  }

  .DayPicker-Day {
    color: #666360;
    background: transparent;
    border: 0;
    border-radius: 0;
  }

  .DayPicker-Day--off100,
  .DayPicker-Day--off50 {
    border: 1px solid black;
  }

  .DayPicker-Day--off100 {
    cursor: pointer;
    border-top: solid 4px #9c1022 !important;
  }

  .DayPicker-Day--off50 {
    border-bottom: solid 4px #d34a4a !important;
  }

  .DayPicker-Day--today {
    font-weight: bold;
  }

  .DayPicker-Day--selected {
    font-weight: bold;
    color: var(--ion-color-primary) !important;
    outline: 0;
    background: transparent !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;

export interface CalendarProps {
  onDayClick: (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  currentMonth: Date;
  selectedDay: Date;
  available50offDays?: Date[];
  available100offDays?: Date[];
  enableAll?: boolean;
  onMonthChange?: (date: Date) => void;
}

export const Calendar: React.FC<CalendarProps> = ({
  onDayClick,
  currentMonth,
  selectedDay,
  available50offDays,
  available100offDays,
  enableAll,
  onMonthChange,
}) => {
  return (
    <Container>
      <DayPicker
        weekdaysShort={["D", "S", "T", "Q", "Q", "S", "S"]}
        onDayClick={onDayClick}
        onMonthChange={(date) => {
          if (onMonthChange) {
            onMonthChange(date);
          }
        }}
        fromMonth={enableAll ? undefined : currentMonth}
        disabledDays={{ before: new Date() }}
        modifiers={{ off50: available50offDays, off100: available100offDays }}
        selectedDays={selectedDay}
        months={[
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ]}
      />
      {available100offDays && (
        <div>
          <LegendBoxItem borderColor="#9c1022" />
          <p style={{ display: "inline" }}>Datas com 100% de desconto</p>
        </div>
      )}
      {available50offDays && (
        <div>
          <LegendBoxItem borderColor="#d34a4a" />
          <p style={{ display: "inline" }}>
            Datas com &nbsp;&nbsp;50% de desconto
          </p>
        </div>
      )}
    </Container>
  );
};

export default Calendar;
