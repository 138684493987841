import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import ProfileCard from "../../ProfileCard";
import { useTakeImage } from "../../../../../../utils/useTakeImage";
import FlexContainer from "../../../../../../components/FlexContainer";
import TextField from "../../../../../../components/TextField";
import TextArea from "../../../../../../components/TextArea";

const BannerInput = styled.button<{ banner?: string }>`
  width: 100%;
  height: 200px;
  color: var(--ion-color-primary);
  border-radius: 10px;
  font-weight: bold;
  border: 2px solid var(--ion-color-secondary);
  background-size: cover;
  background-position: center;
  background-image: ${(props) =>
    props.banner
      ? typeof props.banner === "string"
        ? `url("${props.banner}")`
        : `url("${URL.createObjectURL(props.banner)}")`
      : ""};
`;

const CompanyBasicForm: React.FC<{ disableCNPJ?: boolean }> = ({
  disableCNPJ,
  children,
}) => {
  const { takePhoto } = useTakeImage();
  const { register, watch, setValue } = useFormContext();
  useEffect(() => {
    register("logo");
    register("banner");
  }, []);

  return (
    <FlexContainer direction={"column"} align={"stretch"}>
      <ProfileCard
        onClick={() =>
          takePhoto().then((blob) =>
            setValue("logo", blob, { shouldDirty: true })
          )
        }
        image={watch("logo")}
        name={"Inserir Logomarca"}
      />
      <BannerInput
        type={"button"}
        onClick={() =>
          takePhoto().then((blob) =>
            setValue("banner", blob, { shouldDirty: true })
          )
        }
        banner={watch("banner")}
      >
        <p>Banner</p>
      </BannerInput>

      <TextField label={"Nome do Salão"} name={"name"} />
      <TextField
        label={"CNPJ"}
        name={"cnpj"}
        inputMode={"numeric"}
        mask={"99.999.999/9999-99"}
        disabled={disableCNPJ}
      />
      <TextField label={"Email"} type={"email"} name={"email"} />
      <TextField type={"password"} label={"Senha"} name={"password"} />
      <TextField
        label={"Confirmar Senha"}
        name={"passwordConfirmation"}
        type={"password"}
      />
      <TextField
        type={"tel"}
        label={"Telefone"}
        name={"phone"}
        mask={"(99) 99999-9999"}
      />
      <TextField
        type={"tel"}
        label={"Telefone Fixo"}
        name={"landline"}
        mask={"(99) 9999-9999"}
      />
      <TextArea label={"Sobre a empresa"} name={"about"} />
      <TextField type={"url"} label={"Facebook"} name={"facebookUrl"} />
      <TextField type={"url"} label={"Instagram"} name={"instagram"} />
      <TextField type={"url"} label={"Site"} name={"website"} />
      {children}
    </FlexContainer>
  );
};

export default CompanyBasicForm;
