import { useEffect, useState } from "react";
import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";

import { ICompanyModel } from "../../../../../models/company.model";
import { httpClient } from "../../../../../utils/http-client";
import { FilterState } from "../HomeRoutes";

export interface useCompaniesProps {
  filter: FilterState;
  page: number;
  select?: string[];
  infiniteScrollEl: HTMLIonInfiniteScrollElement | null;
}

const now = new Date();
const initialQuery: CreateQueryParams = {
  join: [
    { field: "services", select: ["id"] },
    { field: "services.slots", select: ["id", "discount", "availableSlots"] },
  ],
  limit: 10,
  search: {
    $and: [
      { approved: true },
      {
        "services.slots.availableSlots": { $gt: 0 },
        "services.slots.startTime": {
          $gte: now.toISOString(),
        },
      },
    ],
  },
};

const useCompanies = ({
  filter,
  page,
  select,
  infiniteScrollEl,
}: useCompaniesProps) => {
  const [companies, setCompanies] = useState<ICompanyModel[] | null>(null);
  useEffect(() => {
    const qb = constructFilter(filter);
    if (select) qb.select(select);
    qb.setPage(page);

    httpClient
      .get(`/companies?${qb.query()}`)
      .then((res) => {
        if (page === 1 || !companies) {
          setCompanies(res.data.data);
        } else {
          setCompanies([...companies, ...res.data.data]);
        }
        if (res.data.data.length < 10) {
          if (infiniteScrollEl) infiniteScrollEl.disabled = true;
        }
      })
      .finally(() => {
        infiniteScrollEl?.complete();
      });
  }, [filter, page, infiniteScrollEl]);

  return { companiesStore: companies };
};

function constructFilter(filter: FilterState) {
  const qb = RequestQueryBuilder.create(initialQuery);
  const search: any = {
    ...initialQuery.search,
    $and: [...initialQuery!.search!.$and!],
  };

  if (filter.tag) {
    qb.setJoin(["tags", ["id"]]);
    search.$and.push({ "tags.id": filter.tag });
  }

  if (filter.discount) {
    search.$and.push({ "services.slots.discount": filter.discount });
  }

  if (filter.searchText) {
    search.$and.push({
      $or: [
        {
          "services.name": {
            $contL: filter.searchText,
          },
        },
        {
          name: {
            $contL: filter.searchText,
          },
        },
      ],
    });
  }

  qb.search(search);
  return qb;
}

export default useCompanies;
