import { types, Instance } from "mobx-state-tree";
import { AddressModel } from "./address.model";

import { AmenityModel } from "./amenity.model";
import { ServiceModel } from "./service.model";

export const CompanyModel = types.model({
  id: types.identifier,
  name: types.maybe(types.string),
  cnpj: types.maybe(types.string),
  email: types.maybe(types.string),
  logo: types.maybe(types.string),
  facebook: types.maybe(types.string),
  instagram: types.maybeNull(types.string),
  phone: types.maybe(types.string),
  site: types.maybe(types.string),
  banner: types.maybe(types.string),
  rating: types.maybe(types.number),
  about: types.maybe(types.string),
  opensAt: types.maybe(types.string),
  closesAt: types.maybe(types.string),
  password: types.maybe(types.string),
  amenities: types.array(AmenityModel),
  address: types.union(types.undefined, types.null, AddressModel),
  services: types.maybe(types.array(ServiceModel)),
  photos: types.maybe(types.array(types.model({id: types.identifier, legend: types.string, url: types.string}))),
});
export interface ICompanyModel extends Instance<typeof CompanyModel> {}

export const CompanyCollection = types
  .model({
    companies: types.array(CompanyModel),
  })
  .actions((self) => ({
    setCompanies(companies: any) {
      self.companies = companies;
    },
  }));
export interface ICompanyCollection
  extends Instance<typeof CompanyCollection> {}
