import * as yup from "yup";

export const CompanyAddressSchema = yup.object().shape({
  street: yup.string().required("Rua obrigatória"),
  cep: yup.string().required("CEP obrigatório"),
  complement: yup.string(),
  number: yup.number().required("Número obrigatório"),
  neighborhood: yup.string().required("Bairro obrigatório"),
  cityId: yup.number().required("Cidade Obrigatória"),
});
