import styled from "styled-components";
import { CSSProperties } from "react";

export interface FlexContainerProps {
  direction?: string;
  align?: string;
  justify?: string;
  flexWrap?: string;
}

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  width: 100%;
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.align || "normal"};
  justify-content: ${(props) => props.justify || "normal"};
`;

export default FlexContainer;
