import React from "react";
import { IonRow } from "@ionic/react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ITagModel } from "../../../../../../models/tag.model";
import { useRootStore } from "../../../../../../models/root-store/root-store.context";

const TagItem = styled.button`
  width: 150px;
  height: 100px;
  display: grid;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.31) 100%,
      rgba(0, 0, 0, 0.31) 100%
    )
    ${(props: { bg: string }) => `,url(${props.bg})`};
  background-size: cover;
  border-radius: 10px;
  color: white;
  place-items: center;
  margin: 8px;
  font-weight: bold;
  text-transform: uppercase;
`;

const TagListFilter: React.FC<{
  setTag: (tag: string) => void;
}> = observer(({ setTag }) => {
  const { tags } = useRootStore();

  return (
    <>
      <IonRow className="ion-justify-content-center ion-align-items-center ion-wrap">
        {tags.map((tag: ITagModel) => (
          <TagItem bg={tag.image} key={tag.id} onClick={() => setTag(tag.id)}>
            {tag.name}
          </TagItem>
        ))}
      </IonRow>
    </>
  );
});

export default TagListFilter;
