import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IonButton,
  IonModal,
  IonPage,
  IonRow,
  IonLoading,
  IonContent,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonCol,
  IonListHeader,
} from "@ionic/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Select from "../../../../components/Select";
import { IServiceModel } from "../../../../models/service.model";
import TextField from "../../../../components/TextField";
import { NewSlotSchema } from "../ConfigTab/schemas/new-coupon.schema";
import { ISlotModel } from "../../../../models/slot.model";
import { httpClient } from "../../../../utils/http-client";

export interface NewSlotModalProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  services: IServiceModel[];
  onDidDismiss: (event?: any) => void;
  date: Date;
}

const NewSlotModal: React.FC<NewSlotModalProps> = ({
  isOpen,
  setOpen,
  services,
  onDidDismiss,
  date,
}) => {
  const modalRef = useRef<HTMLIonModalElement | null>(null);
  const methods = useForm({ resolver: yupResolver(NewSlotSchema) });
  useEffect(() => {
    methods.register("duplicate");
    methods.register("discount");
    methods.setValue("discount", 0.5, { shouldDirty: false });
  }, []);

  const [loading, setLoading] = useState(false);
  const onSubmit = (newSlot: ISlotModel) => {
    setLoading(true);
    const startTime = new Date(date.getTime());
    startTime.setUTCHours(+newSlot.startTime.split(":")[0]);
    startTime.setUTCMinutes(+newSlot.startTime.split(":")[1]);
    startTime.setUTCSeconds(0);
    httpClient
      .post(`/slots/bulk`, {
        bulk: [
          { ...newSlot, startTime },
          ...daysToDuplicate.map((dayToDuplicate: Date) => {
            dayToDuplicate.setUTCHours(startTime.getUTCHours());
            dayToDuplicate.setUTCMinutes(startTime.getUTCMinutes());
            dayToDuplicate.setUTCSeconds(startTime.getUTCSeconds());

            return { ...newSlot, startTime: dayToDuplicate };
          }),
        ],
      })
      .then((res) => {
        modalRef.current!.dismiss(res.data);
      })
      .finally(() => {
        setLoading(false);
        methods.reset();
      });
  };

  const duplicate = methods.watch("duplicate");

  const duplicationQuantity = methods.watch("duplicationQuantity");
  const duplicationInterval = methods.watch("duplicationInterval");
  const daysToDuplicate = useMemo(() => {
    let _daysToDuplicate = [];

    if (duplicationQuantity && duplicationInterval) {
      for (let i = 1; i <= duplicationQuantity; i++) {
        const dateDuplicated = new Date(date.getTime());
        dateDuplicated.setUTCDate(
          dateDuplicated.getDate() + duplicationInterval * i
        );
        _daysToDuplicate.push(dateDuplicated);
      }
    } else {
      _daysToDuplicate = [];
    }

    return _daysToDuplicate;
  }, [duplicationQuantity, duplicationInterval]);

  return (
    <IonModal ref={modalRef} isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <IonPage className={"ion-padding"}>
        <IonContent>
          <IonLoading isOpen={loading} message={"Salvando..."} />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <IonItem>
                <IonLabel>{date.toLocaleDateString()}</IonLabel>
              </IonItem>
              <TextField
                label={"Hora de inicio"}
                name={"startTime"}
                type={"time"}
              />
              <Select
                label={"Desconto"}
                name={"discount"}
                options={[
                  { value: 0.5, label: "50%" },
                  { value: 1, label: "100%" },
                ]}
              />
              <TextField
                label={"Quantidade"}
                name={"availableSlots"}
                type={"number"}
              />
              <Select
                label={"Serviço"}
                name={"serviceId"}
                options={services.map((service) => ({
                  value: service.id,
                  label: service.name,
                }))}
              />
              <IonItem
                lines="none"
                button
                style={{ marginTop: -16, marginBottom: 16 }}
                className="ion-text-end ion-no-margin"
                routerLink="/config/editar/servicos"
              >
                <IonLabel>Adicionar Serviço</IonLabel>
              </IonItem>

              <IonItem lines="none">
                <IonCheckbox
                  defaultChecked={false}
                  slot="start"
                  name="duplicate"
                  onIonChange={(e) =>
                    methods.setValue("duplicate", e.detail.checked)
                  }
                  checked={duplicate}
                />
                <IonLabel>Duplicar</IonLabel>
              </IonItem>

              {duplicate && (
                <>
                  <IonRow>
                    <IonCol>
                      <TextField
                        label={"Quantidade"}
                        name="duplicationQuantity"
                        type={"number"}
                      />
                    </IonCol>
                    <IonCol>
                      <Select
                        name="duplicationInterval"
                        label="Intervalo"
                        options={[
                          {
                            label: "Dia",
                            value: 1,
                          },
                          {
                            label: "Semana",
                            value: 7,
                          },
                          {
                            label: "Mês",
                            value: 30,
                          },
                        ]}
                      />
                    </IonCol>
                  </IonRow>
                  {daysToDuplicate && (
                    <>
                      <IonListHeader>
                        <IonLabel>Dias à duplicar</IonLabel>
                      </IonListHeader>
                      <IonRow>
                        {daysToDuplicate.map((duplicatedDay) => (
                          <IonCol key={duplicatedDay.toISOString()}>
                            <IonItem lines="none">
                              <IonLabel>{`${duplicatedDay
                                .getUTCDate()
                                .toString()
                                .padStart(
                                  2,
                                  "0"
                                )}/${duplicatedDay
                                .getUTCMonth()
                                .toString()
                                .padStart(
                                  2,
                                  "0"
                                )}/${duplicatedDay.getUTCFullYear()}`}</IonLabel>
                            </IonItem>
                          </IonCol>
                        ))}
                      </IonRow>
                    </>
                  )}
                </>
              )}

              <IonRow>
                <IonButton fill={"clear"} onClick={() => setOpen(false)}>
                  Cancelar
                </IonButton>
                <IonButton style={{ flex: 1 }} type={"submit"}>
                  Salvar
                </IonButton>
              </IonRow>
            </form>
          </FormProvider>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default NewSlotModal;
