import React from "react";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";

import TicketsTab from "./TicketsTab";
import SchedulesTab from "./SchedulesTab";

const TicketsRoutes = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/tickets" component={TicketsTab} />
      <Route path="/tickets/calendario" component={SchedulesTab} />
    </IonRouterOutlet>
  );
};

export default TicketsRoutes;
