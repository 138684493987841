import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IonProgressBar } from "@ionic/react";

import FlexContainer from "../../../../../../components/FlexContainer";
import TextField from "../../../../../../components/TextField";
import { httpClient } from "../../../../../../utils/http-client";
import {
  IStateCollection,
  StateCollection,
} from "../../../../../../models/state.model";
import {
  CityCollection,
  ICityCollection,
  ICityModel,
} from "../../../../../../models/city.model";
import Select from "../../../../../../components/Select";
import Autocomplete from "../../../../../../components/Autocomplete";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

const CompanyAddressForm: React.FC<{
  unprefixed?: boolean;
}> = ({ children, unprefixed }) => {
  const { watch } = useFormContext();

  const [states, setStates] = useState<IStateCollection | null>(null);
  useEffect(() => {
    httpClient
      .get(
        `/states?${RequestQueryBuilder.create({
          sort: [{ field: "name", order: "ASC" }],
        }).query()}`
      )
      .then((res) =>
        setStates(StateCollection.create({ collection: res.data }))
      );
  }, []);

  const [cities, setCities] = useState<ICityModel[]>([]);
  const addressStateId = watch(unprefixed ? "stateId" : "address.stateId");
  useEffect(() => {
    if (!addressStateId) return;
    setCities([]);
    httpClient
      .get(`/cities?join=state&filter=state.id||$eq||${addressStateId}`)
      .then((res) => {
        setCities(res.data);
      });
  }, [addressStateId]);

  return (
    <FlexContainer direction={"column"} align={"stretch"}>
      <TextField
        label={"Rua"}
        name={unprefixed ? "street" : "address.street"}
      />
      <TextField
        label={"Número"}
        type={"number"}
        name={unprefixed ? "number" : "address.number"}
      />
      <TextField
        label={"Bairro"}
        name={unprefixed ? "neighborhood" : "address.neighborhood"}
      />
      <TextField
        label={"CEP"}
        name={unprefixed ? "cep" : "address.cep"}
        mask={"99999-999"}
        inputMode={"numeric"}
      />
      <TextField
        label={"Complemento"}
        name={unprefixed ? "complement" : "address.complement"}
      />

      {states ? (
        <Select
          label={"Estado"}
          name={unprefixed ? "stateId" : "address.stateId"}
          options={states.collection.map((state) => ({
            value: state.id,
            label: `${state.name}-${state.abbreviation}`,
          }))}
        />
      ) : (
        <IonProgressBar type={"indeterminate"} />
      )}

      {addressStateId ? (
        cities.length > 0 ? (
          <Autocomplete
            label={"Cidades"}
            name={unprefixed ? "cityId" : "address.cityId"}
            options={cities.map((city) => ({
              value: city.id,
              label: city.name,
            }))}
          />
        ) : (
          <IonProgressBar type={"indeterminate"} />
        )
      ) : null}
      {children}
    </FlexContainer>
  );
};

export default CompanyAddressForm;
