import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
} from "@ionic/react";
import { RouteComponentProps } from "react-router-dom";
import { arrowBack, checkmark, close, star } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { LaunchNavigator } from "@ionic-native/launch-navigator";
import { Plugins } from "@capacitor/core";

import {
  CompanyModel,
  ICompanyModel,
} from "../../../../../models/company.model";
import { httpClient } from "../../../../../utils/http-client";
import CompanyDetailsHeader from "../../../../../components/CompanyDetailsHeader";
import RatingList from "../../../../../components/RatingList";
import { useRootStore } from "../../../../../models/root-store/root-store.context";
import BuyCoupons from "./BuyCoupons";
import Container from "../../../../../components/Container";
import ServicesList from "./ServicesList";
import { IServiceModel } from "../../../../../models/service.model";
import { Helmet } from "react-helmet";
import { usePlatform } from "../../../../../utils/usePlatform";
import CompanyPhotoSlide from "../../../../../components/CompanyPhotosSlide";

const { Browser } = Plugins;

const CompanyDetailTab: React.FC<
  RouteComponentProps<{ id: string }>
> = observer(({ match, history }) => {
  const platform = usePlatform();

  const {
    amenities,
    auth: { userDecoded },
  } = useRootStore();
  const [segmentActive, setSegmentActive] = useState<
    "details" | "comments" | "coupons"
  >("coupons");
  const [companyStore, setCompanyStore] = useState<ICompanyModel | null>(null);
  useEffect(() => {
    httpClient
      .get(
        `/companies/${match.params.id}?join=amenities&join=address&join=photos`
      )
      .then((res) => setCompanyStore(CompanyModel.create(res.data)));
  }, [match.params]);

  const [serviceSelected, setServiceSelected] = useState<IServiceModel | null>(
    null
  );
  useEffect(() => {
    setServiceSelected(null);
  }, [match.params.id]);

  if (!companyStore) return <IonProgressBar type={"indeterminate"} />;
  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <Helmet title={"Perfil da Empresa"} />
      <IonContent>
        <CompanyDetailsHeader banner={companyStore.banner}>
          <IonButton
            onClick={() => history.replace("/empresas")}
            color={"secondary"}
            fill={"clear"}
          >
            <IonIcon icon={arrowBack} slot={"icon-only"} />
          </IonButton>

          <IonRow>
            <IonAvatar style={{ width: 64 }} className={"ion-margin-start"}>
              <img src={companyStore.logo} alt="" />
            </IonAvatar>
            <div className={"ion-margin-start"}>
              <IonText color={"light"}>
                <p className={"ion-no-margin"}>{companyStore.name}</p>
              </IonText>
              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
                className={"ion-no-margin"}
              >
                <IonIcon
                  icon={star}
                  color={"warning"}
                  style={{ marginRight: 4 }}
                />
                {companyStore.rating}
              </p>
              <p style={{ color: "white" }}>
                {companyStore.opensAt!.slice(0, 5)}h às{" "}
                {companyStore.closesAt!.slice(0, 5)}h
              </p>
            </div>
          </IonRow>
        </CompanyDetailsHeader>
        <IonSegment
          value={segmentActive}
          onIonChange={(e) => {
            setSegmentActive(e.detail.value! as any);
          }}
        >
          <IonSegmentButton value={"coupons"}>OFERTAS</IonSegmentButton>
          <IonSegmentButton value={"details"}>DETALHES</IonSegmentButton>
          <IonSegmentButton value={"comments"}>COMENTÁRIOS</IonSegmentButton>
        </IonSegment>

        <Container>
          {segmentActive === "details" && (
            <>
              <section>
                <IonListHeader>
                  <IonLabel className={"text-bold"} color="primary">
                    Sobre nós
                  </IonLabel>
                </IonListHeader>
                <p
                  className={"ion-margin ion-text-justify"}
                  style={{ maxWidth: "50ch" }}
                >
                  {companyStore.about}
                </p>
              </section>
              <section>
                <IonList lines="none">
                  {companyStore.photos && companyStore.photos.length > 0 && (
                    <>
                      <IonListHeader>
                        <IonLabel className={"text-bold"} color="primary">
                          Fotos
                        </IonLabel>
                      </IonListHeader>
                      <CompanyPhotoSlide photos={companyStore.photos} />
                    </>
                  )}

                  <IonListHeader>
                    <IonLabel className={"text-bold"} color="primary">
                      Contatos
                    </IonLabel>
                  </IonListHeader>
                  <IonItem>
                    <IonLabel>
                      <h2>Email</h2>
                      <h3>{companyStore.email}</h3>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h2>Telefone</h2>
                      <h3>{companyStore.phone}</h3>
                    </IonLabel>
                  </IonItem>
                  {companyStore.address && (
                    <IonItem>
                      <IonLabel>
                        <h2>Endereço</h2>
                        <h3 className={"ion-text-wrap"}>
                          {companyStore.address.street}{" "}
                          {companyStore.address.number}{" "}
                          {companyStore.address.neighborhood}{" "}
                          {companyStore.address.cep}
                        </h3>
                      </IonLabel>

                      <IonButton
                        slot={"end"}
                        fill={"outline"}
                        onClick={() => {
                          if (platform !== "web") {
                            LaunchNavigator.navigate(
                              `${companyStore.address!.street} ${
                                companyStore.address!.number
                              } ${companyStore.address!.neighborhood} ${
                                companyStore.address!.cep
                              }`
                            );
                          } else {
                            Browser.open({
                              url: `https://www.google.com/maps/dir//${
                                companyStore.address!.street
                              } ${companyStore.address!.number} ${
                                companyStore.address!.neighborhood
                              } ${companyStore.address!.cep}`,
                            });
                          }
                        }}
                      >
                        Ver no mapa
                      </IonButton>
                    </IonItem>
                  )}
                </IonList>
              </section>

              <section>
                <IonList>
                  <IonListHeader>
                    <IonLabel className={"text-bold"} color="primary">
                      Conforto
                    </IonLabel>
                  </IonListHeader>
                  {amenities.map((amenity) => (
                    <IonItem lines={"none"} key={amenity.id}>
                      <IonLabel>{amenity.name}</IonLabel>
                      {!!companyStore.amenities!.find(
                        (companyAmenity) => companyAmenity.id === amenity.id
                      ) ? (
                        <IonIcon
                          title={`Possui ${amenity.name}`}
                          icon={checkmark}
                          color={"success"}
                        />
                      ) : (
                        <IonIcon
                          title={`Não possui ${amenity.name}`}
                          icon={close}
                          color={"danger"}
                        />
                      )}
                    </IonItem>
                  ))}
                </IonList>
              </section>
            </>
          )}

          {segmentActive === "comments" && (
            <RatingList companyId={match.params.id} />
          )}

          {segmentActive === "coupons" &&
            (!serviceSelected ? (
              <ServicesList
                setServiceSelected={setServiceSelected}
                userDecoded={userDecoded}
                companyId={match.params.id}
              />
            ) : (
              <BuyCoupons
                serviceSelected={serviceSelected}
                setServiceSelected={setServiceSelected}
                userDecoded={userDecoded}
              />
            ))}
        </Container>
      </IonContent>
    </IonPage>
  );
});

export default CompanyDetailTab;
