import { Instance, types } from "mobx-state-tree";

export interface IStateModel extends Instance<typeof StateModel> {}
export const StateModel = types.model({
  id: types.number,
  name: types.string,
  abbreviation: types.string,
});

export interface IStateCollection extends Instance<typeof StateCollection> {}
export const StateCollection = types.model({
  collection: types.array(StateModel),
});
