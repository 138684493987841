import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  IonList,
  IonModal,
  IonSearchbar,
  IonPage,
  IonItem,
  IonLabel,
  IonProgressBar,
  IonButton,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonContent,
} from "@ionic/react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { close } from "ionicons/icons";
import { Plugins } from "@capacitor/core";

import { useRootStore } from "../models/root-store/root-store.context";
import { httpClient } from "../utils/http-client";

const { Device } = Plugins;

const LocationModal: React.FC = observer(() => {
  const [platform, setPlatform] = useState<
    "ios" | "android" | "electron" | "web" | null
  >(null);
  useEffect(() => {
    Device.getInfo().then((device) => setPlatform(device.platform));
  }, []);

  const { setModalCities, setCityId, cityId, modalCitiesOpen } = useRootStore();
  const [citiesWithCompanies, setCitiesWithCompanies] = useState<any[] | null>(
    null
  );
  useEffect(() => {
    httpClient
      .get(
        `/cities?${RequestQueryBuilder.create({
          join: ["companiesAddresses", ["id"]],
          fields: ["id", "name"],
        }).query()}`
      )
      .then((res) => setCitiesWithCompanies(res.data));
  }, [modalCitiesOpen]);

  const [searchTerm, setSearchTerm] = useState("");
  const citiesFiltered = useMemo(() => {
    if (!citiesWithCompanies) return [];
    if (!searchTerm) return [...citiesWithCompanies];
    return citiesWithCompanies.filter((city) => city.name.includes(searchTerm));
  }, [citiesWithCompanies, searchTerm]);

  return (
    <IonModal
      isOpen={modalCitiesOpen}
      onDidDismiss={() => setModalCities(false)}
    >
      <IonPage style={{ padding: 24 }}>
        <IonContent>
          <div className={platform === "ios" ? "padding-notch" : ""}>
            <IonToolbar>
              {cityId && (
                <IonButtons slot={"start"}>
                  <IonButton
                    style={{ marginBottom: 8 }}
                    fill={"clear"}
                    onClick={() => setModalCities(false)}
                    size={"small"}
                  >
                    <IonIcon slot={"icon-only"} icon={close} />
                  </IonButton>
                </IonButtons>
              )}
              <IonSearchbar
                mode={"ios"}
                placeholder={"Cidade"}
                value={searchTerm}
                onIonChange={(e) => setSearchTerm(e.detail.value!)}
              />
            </IonToolbar>
            {citiesWithCompanies ? (
              citiesFiltered.length > 0 ? (
                <IonList>
                  {citiesFiltered.map((city) => (
                    <IonItem
                      key={city.id}
                      button
                      onClick={() => {
                        setCityId(city.id.toString());
                        setModalCities(false);
                      }}
                    >
                      <IonLabel>{city.name}</IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              ) : (
                <IonButton fill={"outline"} className={"ion-text-wrap"}>
                  Não há, cadastre seu salão e seja o primeiro na cidade
                </IonButton>
              )
            ) : (
              <IonProgressBar type={"indeterminate"} />
            )}
          </div>
        </IonContent>
      </IonPage>
    </IonModal>
  );
});

export default LocationModal;
