import React from "react";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";

export interface InfiniteScrollProps {
  infiniteScrollEl: React.RefObject<HTMLIonInfiniteScrollElement>;
  nextPage: () => void;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  infiniteScrollEl,
  nextPage,
}) => (
  <IonInfiniteScroll
    ref={infiniteScrollEl}
    threshold="100px"
    onIonInfinite={() => nextPage()}
  >
    <IonInfiniteScrollContent loadingSpinner="dots" />
  </IonInfiniteScroll>
);

export default InfiniteScroll;
