import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "@codetrix-studio/capacitor-google-auth";
import { Helmet } from "react-helmet";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonToggle,
} from "@ionic/react";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import { Plugins, registerWebPlugin } from "@capacitor/core";
import {
  FacebookLogin,
  FacebookLoginResponse,
} from "@capacitor-community/facebook-login";
import "@codetrix-studio/capacitor-google-auth";
import { logoApple, logoFacebook, logoGoogle } from "ionicons/icons";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import jwtDecode from "jwt-decode";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import TextField from "../../../../components/TextField";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import { CustomerToCreate } from "./ProfileRoutes";
import { Entity, JwtPayload } from "../../../../models/auth.model";
import { usePlatform } from "../../../../utils/usePlatform";

const {
  SignInWithApple: SignInWithApplePlugin,
  Device,
  FacebookLogin: FacebookLoginPlugin,
  GoogleAuth,
  Storage,
} = Plugins;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
`;

interface FormData {
  email: string;
  password: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

declare var FB: any;

const Login: React.FC<
  RouteComponentProps & {
    setCustomerToCreate: Dispatch<SetStateAction<CustomerToCreate | null>>;
  }
> = observer(({ history, setCustomerToCreate }) => {
  const platform = usePlatform();

  useEffect(() => {
    registerWebPlugin(FacebookLogin);
    window.fbAsyncInit = function () {
      FB.init({
        appId: "411820633319272",
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: "v9.0", // use graph api current version
      });
    };
  }, []);

  const {
    auth: { setAccessToken },
  } = useRootStore();
  const [loading, setLoading] = useState(false);
  const [loginAsSalon, setLoginAsSalon] = useState(false);
  const methods = useForm<FormData>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (formData: FormData) => {
    setLoading(true);
    httpClient
      .post(`/auth/${loginAsSalon ? "companies" : "customers"}/login`, {
        email: formData.email,
        password: formData.password,
      })
      .then((res) => {
        onSuccessLogin(res?.data?.accessToken);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetPassword = () => {
    const email = window.prompt(
      `Digite o email de ${loginAsSalon ? "sua empresa" : "seu usuário"}`
    );
    if (email) {
      setLoading(true);
      httpClient
        .post("/auth/forgot-password", {
          email,
          entity: loginAsSalon ? "company" : "customer",
        })
        .then((res) =>
          window.alert(
            "Acesse o email enviado na sua conta para resetar sua senha"
          )
        )
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loginWithFacebook = () => {
    setLoading(true);
    FacebookLoginPlugin.login({
      permissions: ["email"],
    })
      .then(({ accessToken }: FacebookLoginResponse) =>
        httpClient
          .post("/auth/facebook/login", { accessToken: accessToken!.token })
          .then((res) => {
            onSuccessLogin(res?.data?.accessToken);
          })
      )
      .catch((error: any) => {
        if (error?.response?.status === 424) {
          setCustomerToCreate(error.response.data);
          history.push("/boas-vindas/cliente");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loginWithGoogle = () => {
    setLoading(true);
    GoogleAuth.signIn()
      .then(({ authentication: { idToken } }: any) =>
        httpClient.post("/auth/google/login", { idToken }).then((res) => {
          onSuccessLogin(res?.data?.accessToken);
        })
      )
      .catch((error: any) => {
        if (error?.response?.status === 424) {
          setCustomerToCreate(error.response.data);
          history.push("/boas-vindas/cliente");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loginWithApple = () => {
    setLoading(true);
    SignInWithApplePlugin.authorize({
      clientId: "br.com.beauticket",
      scopes: "email name",
    } as SignInWithAppleOptions)
      .then(
        ({
          response: { identityToken, user, givenName, familyName },
        }: SignInWithAppleResponse) => {
          const name = givenName ? `${givenName} ${familyName}` : null;
          httpClient
            .post("/auth/apple/login", { name, identityToken, user })
            .then((res) => {
              onSuccessLogin(res?.data?.accessToken);
            })
            .catch((error: any) => {
              if (error?.response?.status === 424) {
                setCustomerToCreate(error.response.data);
                history.push("/boas-vindas/cliente");
              }
            });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const onSuccessLogin = async (accessToken: string) => {
    if (accessToken) {
      const { value: fcmToken } = await Storage.get({ key: "fcmToken" });
      if (fcmToken) {
        const newDeviceToken: {
          token: string;
          deviceType: string;
          customerId?: string;
          companyId?: string;
        } = { token: fcmToken as any, deviceType: platform as any };

        const userDecoded: JwtPayload = jwtDecode(accessToken);
        if (userDecoded.entity === Entity.COMPANY) {
          newDeviceToken.companyId = userDecoded.sub;
        } else {
          newDeviceToken.customerId = userDecoded.sub;
        }

        httpClient.post("/tokens", newDeviceToken);
      }

      setAccessToken(accessToken).then(() =>
        history.replace(loginAsSalon ? "/tickets" : "/empresas")
      );
    }
  };

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <Helmet title={"Entrar"} />
      <Helmet>
        <script>
          {`(function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/pt_BR/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'))`}
        </script>
      </Helmet>
      <IonContent>
        <IonLoading isOpen={loading} message="Aguarde..." />
        <WrapperWithFooter>
          <Grid className="ion-padding">
            <img
              width={200}
              style={{ alignSelf: "center" }}
              src="/assets/images/LogotipoBeauticket.png"
              alt=""
            />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TextField label={"Email"} name={"email"} type={"email"} />
                <TextField
                  label={"Senha"}
                  name={"password"}
                  type={"password"}
                />
                <IonButton
                  fill="clear"
                  onClick={resetPassword}
                  size="small"
                  style={{
                    textTransform: "none",
                    marginLeft: "100%",
                    transform: "translateX(-100%)",
                  }}
                >
                  Esqueci minha senha
                </IonButton>

                <IonItem lines="none">
                  <IonLabel>Entrar Como Salão</IonLabel>
                  <IonToggle
                    slot="end"
                    checked={loginAsSalon}
                    onIonChange={(e) => setLoginAsSalon(e.detail.checked)}
                  />
                </IonItem>
                <IonButton
                  type={"submit"}
                  className="text-bold"
                  expand={"full"}
                  color="primary"
                >
                  Entrar
                </IonButton>
                {!loginAsSalon && (
                  <>
                    <IonButton
                      fill={"clear"}
                      className="text-bold"
                      expand={"full"}
                      color="primary"
                      onClick={loginWithFacebook}
                    >
                      <IonIcon icon={logoFacebook} slot={"start"} />
                      Entrar com Facebook
                    </IonButton>
                    <IonButton
                      fill={"clear"}
                      className="text-bold"
                      expand={"full"}
                      color="primary"
                      onClick={loginWithGoogle}
                    >
                      <IonIcon icon={logoGoogle} slot={"start"} />
                      Entrar com Google
                    </IonButton>
                    {platform === "ios" && (
                      <IonButton
                        fill={"clear"}
                        className="text-bold"
                        expand={"full"}
                        onClick={loginWithApple}
                        color="primary"
                      >
                        <IonIcon icon={logoApple} slot={"start"} />
                        Entrar com Apple
                      </IonButton>
                    )}
                  </>
                )}
              </form>
            </FormProvider>

            <IonButton
              fill={"clear"}
              className="text-bold"
              expand={"full"}
              color="primary"
              style={{ marginTop: 32 }}
              routerLink={"/boas-vindas/cliente"}
            >
              Cadastrar
            </IonButton>
            <IonButton
              routerLink={"/boas-vindas/empresa"}
              fill={"outline"}
              expand="block"
            >
              Cadastre seu salão
            </IonButton>
          </Grid>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default Login;
