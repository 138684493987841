import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import { ICompanyModel } from "../../../../models/company.model";
import { dirtyFormValues } from "../../../../utils/getDirtyFormValues";
import { CompanyAddressSchema } from "../../../CustomerPages/tabs/ProfileTab/validation-schemas/company/company-address.schema";
import CompanyAddressForm from "../../../CustomerPages/tabs/ProfileTab/components/SlidesCompanyForm/CompanyAddressForm";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import {Helmet} from "react-helmet";

const EditAddress: React.FC<RouteComponentProps> = observer(({ history }) => {
  const {
    auth: { userDecoded },
  } = useRootStore();

  const methods = useForm({ resolver: yupResolver(CompanyAddressSchema) });
  const { formState } = methods;

  const [loadingCurrentData, setLoadingCurrentData] = useState(true);
  const [addressId, setAddressId] = useState<string | null>(null);
  useEffect(() => {
    httpClient
      .get(
        `/company-addresses?${RequestQueryBuilder.create({
          join: [["city", ["id", "stateId"]]],
          filter: [
            { field: "companyId", operator: "$eq", value: userDecoded!.sub },
          ],
        }).query()}`
      )
      .then(({ data }) => {
        methods.register("stateId");
        methods.setValue("stateId", data[0].city.stateId, {
          shouldDirty: false,
        });
        Object.keys(data[0]).forEach((key) => {
          if (data[0][key]) {
            methods.register(key);
            methods.setValue(key, data[0][key] || "", {
              shouldDirty: false,
            });
          }
        });
        methods.register("cityId");
        methods.setValue("cityId", data[0].cityId, {
          shouldDirty: false,
        });
        setAddressId(data[0].id);
      })
      .finally(() => {
        setLoadingCurrentData(false);
      });
  }, [userDecoded]);

  const onSubmit = (formValues: ICompanyModel) => {
    const dirtyValues: any = dirtyFormValues(formState.dirtyFields, formValues);
    if (Object.keys(dirtyValues).length > 0)
      httpClient
        .patch(`/company-addresses/${addressId!}`, dirtyValues)
        .then((res) => {
          history.replace("/config");
        });
  };

  return (
    <IonPage>
      <IonHeader>
        <Helmet title={'Editar Endereço'}/>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonBackButton defaultHref={"/config"} />
          </IonButtons>
          <IonTitle>Endereço</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WrapperWithFooter>
          <Container>
            <div style={{ padding: 24 }}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {loadingCurrentData ? (
                    <IonProgressBar type="indeterminate" />
                  ) : (
                    <CompanyAddressForm unprefixed />
                  )}
                  <IonButton
                    disabled={!formState.isDirty}
                    className="ion-margin-top"
                    expand={"block"}
                    type={"submit"}
                  >
                    Salvar
                  </IonButton>
                </form>
              </FormProvider>
            </div>
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default EditAddress;
