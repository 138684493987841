import { IonItem } from "@ionic/react";
import styled from "styled-components";

const IonItemOutlined = styled(IonItem)`
  border: 2px solid var(--ion-color-primary);
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export default IonItemOutlined;
