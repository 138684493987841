import React from "react";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";

import CompanyProfileTab from "./CompanyProfileTab";

const CompanyProfileRoutes = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/perfil" component={CompanyProfileTab} />
    </IonRouterOutlet>
  );
};

export default CompanyProfileRoutes;
