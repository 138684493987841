import {
  Plugins,
  PushNotification,
  PushNotificationToken,
} from "@capacitor/core";
import firebase from "firebase/app";
import "firebase/messaging";

const { PushNotifications, Storage, Toast } = Plugins;

export default async function setupFirebaseMessaging(
  platform: "web" | "ios" | "android"
) {
  let currentToken;
  if (platform !== "web") {
    const result = await PushNotifications.requestPermission();
    if (result.granted) {
      PushNotifications.register();
    }

    PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        Storage.set({ key: "fcmToken", value: token.value });
      }
    );

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotification) => {
        if (notification.body)
          Toast.show({
            text: notification.body,
            duration: "long",
          });
      }
    );
  } else if (window.location.protocol === "https:") {
    const firebaseConfig = {
      apiKey: "AIzaSyC70kcxheSvZR4Jk9mXptAka1lj7ezu2eE",
      authDomain: "beauticket-c3f39.firebaseapp.com",
      projectId: "beauticket-c3f39",
      storageBucket: "beauticket-c3f39.appspot.com",
      messagingSenderId: "520780199356",
      appId: "1:520780199356:web:98715343c5eaf99f8fa7b9",
      measurementId: "G-C8W3JZW4VB",
    };
    const app = firebase.initializeApp(firebaseConfig);
    if (firebase.messaging.isSupported()) {
      const messaging = app.messaging();
      currentToken = await messaging
        .getToken({
          vapidKey:
            "BOg31MfphMZAEofjvGdAzuFMjb3YvCS6kC8YQmaoH3MhqHo7M_0RHARocg8szhpM0CEPBhVzl6b8To2Rzek76Ck",
        })
        .catch(() => {
          /* Catch para evitar quebrar a aplicação e deixar tela branca z*/
        });
    }
  }

  return currentToken;
}
