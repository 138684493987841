import "react-day-picker/lib/style.css";
import React, { useEffect, useMemo, useState } from "react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  IonAvatar,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import { observer } from "mobx-react-lite";
import { person } from "ionicons/icons";
import { useMediaQuery } from "react-responsive";

import Calendar from "../../../../components/Calendar";
import { httpClient } from "../../../../utils/http-client";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import { ICouponModel } from "../../../../models/coupon.model";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import { Helmet } from "react-helmet";
import { usePlatform } from "../../../../utils/usePlatform";
import { Discount } from "../../../../models/service.model";

const now = new Date();
const SchedulesTab = observer(() => {
  const {
    auth: { userDecoded },
  } = useRootStore();
  const isTabletOrUp = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const [selectedDay, setSelectedDay] = useState<Date>(now);

  const [monthChanged, setMonthChanged] = useState(true);
  const [available100offDays, setAvailable100offDays] = useState<Date[]>([]);
  const [available50offDays, setAvailable50offDays] = useState<Date[]>([]);
  useEffect(() => {
    if (monthChanged) {
      const firstDateOfMonth = new Date(selectedDay.getTime());
      firstDateOfMonth.setUTCDate(1);
      firstDateOfMonth.setUTCHours(0);
      firstDateOfMonth.setUTCMinutes(0);
      firstDateOfMonth.setUTCSeconds(0);

      const lastDateOfMonth = new Date(selectedDay.getTime());
      lastDateOfMonth.setUTCDate(
        new Date(
          selectedDay.getFullYear(),
          selectedDay.getMonth() + 1,
          0
        ).getUTCDate()
      );
      lastDateOfMonth.setUTCHours(23);
      lastDateOfMonth.setUTCMinutes(59);
      lastDateOfMonth.setUTCSeconds(59);

      httpClient
        .get(
          `/coupons?${RequestQueryBuilder.create({
            filter: [
              { field: "companyId", operator: "$eq", value: userDecoded?.sub },
              {
                field: "bookDate",
                operator: "$between",
                value: [
                  firstDateOfMonth.toISOString(),
                  lastDateOfMonth.toISOString(),
                ],
              },
            ],
          }).query()}`
        )
        .then((res) => {
          let _available50offDays: Date[] = [];
          let _available100offDays: Date[] = [];

          res.data.forEach((coupon: ICouponModel) => {
            if (coupon.discount == Discount.HALF) {
              _available50offDays.push(new Date(coupon.bookDate));
            } else {
              _available100offDays.push(new Date(coupon.bookDate));
            }
          });

          setAvailable100offDays([..._available100offDays]);
          setAvailable50offDays([..._available50offDays]);
        })
        .finally(() => {
          setMonthChanged(false);
        });
    }
  }, [userDecoded, monthChanged]);

  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState<{ [time: string]: ICouponModel[] }>(
    {}
  );
  useEffect(() => {
    setLoading(true);
    const midnight = new Date(selectedDay.getTime());
    midnight.setUTCHours(0);
    midnight.setUTCMinutes(0);
    midnight.setUTCSeconds(0);
    const finalDateTime = new Date(selectedDay.getTime());
    finalDateTime.setUTCHours(23);
    finalDateTime.setUTCMinutes(59);
    finalDateTime.setUTCSeconds(59);

    httpClient
      .get(
        `/coupons?${RequestQueryBuilder.create({
          join: [
            { field: "customer", select: ["id", "avatar", "name"] },
            { field: "company", select: ["id"] },
          ],
          sort: [{ field: "bookDate", order: "ASC" }],
          filter: [
            { field: "companyId", operator: "$eq", value: userDecoded!.sub },
            {
              field: "bookDate",
              operator: "$between",
              value: [midnight.toISOString(), finalDateTime.toISOString()],
            },
          ],
        }).query()}`
      )
      .then((res) => {
        setCoupons(
          res.data.reduce((groupedByDate: any, coupon: ICouponModel) => {
            const time = coupon.bookDate.slice(11, 16);
            if (!groupedByDate[time]) {
              groupedByDate[time] = [];
            }
            groupedByDate[time].push(coupon);
            return groupedByDate;
          }, {} as any)
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedDay, userDecoded]);

  const schedulesTime = useMemo(() => {
    return Object.keys(coupons);
  }, [coupons]);

  const redeemCoupon = (time: string, coupon: ICouponModel) => {
    if (
      window.confirm(
        "Deseja marcar cupom como resgatado? Confirme se o cliente compareceu no estabelecimento"
      )
    ) {
      httpClient
        .patch(`/coupons/${coupon.id}`, { redeemed: true })
        .then((res) => {
          const newCoupons = { ...coupons };
          newCoupons[time] = newCoupons[time].map((currentCoupon) => {
            if (coupon.id === currentCoupon.id) {
              currentCoupon.redeemed = true;
            }
            return currentCoupon;
          });
          setCoupons(newCoupons);
        });
    }
  };

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Calendario de Agendamentos"} />
        <WrapperWithFooter>
          <Container>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/tickets" />
              </IonButtons>
              <IonTitle>Calendário</IonTitle>
              <IonButtons slot="end">
                <IonButton color="primary" routerLink="/criar-tickets">
                  Adicionar Ofertas
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <div
              style={{
                display: "flex",
                flexDirection: isTabletOrUp ? "row" : "column",
              }}
            >
              <Calendar
                available50offDays={available50offDays}
                available100offDays={available100offDays}
                enableAll
                onMonthChange={() => setMonthChanged(true)}
                onDayClick={(day: Date) => setSelectedDay(day)}
                currentMonth={now}
                selectedDay={selectedDay}
              />
              {loading ? (
                <IonSpinner
                  name={"dots"}
                  className={"ion-margin-top"}
                  style={{ marginLeft: "50%", transform: "translateX(-50%)" }}
                />
              ) : (
                <div style={{ width: "100%" }}>
                  <IonListHeader className={"ion-margin-start"}>
                    <IonLabel>{selectedDay.toLocaleDateString()}</IonLabel>
                  </IonListHeader>
                  <Timeline style={{ paddingLeft: 0 }}>
                    {schedulesTime.length > 0 ? (
                      schedulesTime.map((time) => (
                        <TimelineItem key={time}>
                          <TimelineOppositeContent
                            style={{ flex: 0, paddingLeft: 8 }}
                          >
                            <Typography color="textSecondary">
                              {time}
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot color="secondary" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            {coupons[time].map((coupon: ICouponModel) => (
                              <IonItem key={coupon.id}>
                                <IonLabel className="ion-text-wrap">
                                  <IonAvatar slot="start">
                                    {coupon.customer!.avatar ? (
                                      <img
                                        src={coupon.customer!.avatar}
                                        alt=""
                                      />
                                    ) : (
                                      <IonIcon size={"large"} icon={person} />
                                    )}
                                  </IonAvatar>
                                  <h2>{coupon.customer!.name}</h2>
                                  <h3>Procedimento: {coupon.serviceName}</h3>
                                  <p>
                                    Cupom {Math.round(coupon.discount * 100)}%:{" "}
                                    <strong>{coupon.token}</strong>
                                  </p>
                                  <p>
                                    Valor do serviço no momento da compra:{" "}
                                    {currencyFormatter.format(
                                      coupon.servicePrice
                                    )}
                                  </p>
                                  <p>
                                    Valor à receber:{" "}
                                    {currencyFormatter.format(
                                      +coupon.servicePrice -
                                        coupon.servicePrice * coupon.discount
                                    )}
                                  </p>
                                  <IonButton
                                    disabled={coupon.redeemed}
                                    fill={"clear"}
                                    onClick={() => redeemCoupon(time, coupon)}
                                  >
                                    {coupon.redeemed
                                      ? "Resgatado"
                                      : "Marcar como resgatado"}
                                  </IonButton>
                                </IonLabel>
                              </IonItem>
                            ))}
                          </TimelineContent>
                        </TimelineItem>
                      ))
                    ) : (
                      <p className="ion-margin">
                        Não há agendamento disponível
                      </p>
                    )}
                  </Timeline>
                </div>
              )}
            </div>
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default SchedulesTab;
