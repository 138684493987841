import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonContent,
  IonSegment,
  IonIcon,
  IonSegmentButton,
  IonProgressBar,
  IonAvatar,
  IonRow,
  IonText,
  IonList,
  IonItem,
  IonLabel,
  IonListHeader,
  IonButton,
} from "@ionic/react";
import { checkmark, close, star } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { httpClient } from "../../../../utils/http-client";
import CompanyDetailsHeader from "../../../../components/CompanyDetailsHeader";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import RatingList from "../../../../components/RatingList";
import Container from "../../../../components/Container";
import { usePlatform } from "../../../../utils/usePlatform";
import CompanyPhotoSlide from "../../../../components/CompanyPhotosSlide";

const CompanyProfileTab: React.FC = observer(() => {
  const [segmentActive, setSegmentActive] = useState<"details" | "comments">(
    "details"
  );

  const {
    amenities,
    auth: { userDecoded, setCompany, companyLoggedIn },
  } = useRootStore();
  useEffect(() => {
    httpClient
      .get(
        `/companies/${userDecoded!.sub}?join=amenities&join=address&join=photos`
      )
      .then((res) => setCompany(res.data));
  }, []);

  const platform = usePlatform();

  if (!companyLoggedIn) return <IonProgressBar type={"indeterminate"} />;
  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Perfil"} />
        <CompanyDetailsHeader banner={companyLoggedIn.banner}>
          <IonRow style={{ paddingTop: 32 }}>
            <IonAvatar style={{ width: 64 }} className={"ion-margin-start"}>
              <img src={companyLoggedIn.logo} alt="" />
            </IonAvatar>
            <div className={"ion-margin-start"}>
              <IonText color={"light"}>
                <p className={"ion-no-margin"}>{companyLoggedIn.name}</p>
              </IonText>
              <p
                style={{
                  color: "white",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
                className={"ion-no-margin"}
              >
                <IonIcon
                  icon={star}
                  color={"warning"}
                  style={{ marginRight: 4 }}
                />
                {companyLoggedIn.rating}
              </p>
            </div>
          </IonRow>
        </CompanyDetailsHeader>
        <IonSegment
          value={segmentActive}
          onIonChange={(e) => setSegmentActive(e.detail.value! as any)}
        >
          <IonSegmentButton value={"details"}>DETALHES</IonSegmentButton>
          <IonSegmentButton value={"comments"}>COMENTÁRIOS</IonSegmentButton>
        </IonSegment>
        <Container>
          {segmentActive === "details" && (
            <>
              <IonList lines="none">
                <IonListHeader>
                  <IonLabel color="primary">Fotos</IonLabel>
                </IonListHeader>
                <IonButton routerLink="/config/editar/fotos" fill="clear">
                  Adicionar Foto
                </IonButton>
                <CompanyPhotoSlide photos={companyLoggedIn.photos} />

                <IonListHeader>
                  <IonLabel color="primary">Contatos</IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonLabel>
                    <h2>Email</h2>
                    <h3>{companyLoggedIn.email}</h3>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h2>Telefone</h2>
                    <h3>{companyLoggedIn.phone}</h3>
                  </IonLabel>
                </IonItem>
                {companyLoggedIn.address && (
                  <IonItem>
                    <IonLabel>
                      <h2>Endereço</h2>
                      <h3>
                        {companyLoggedIn.address.street}{" "}
                        {companyLoggedIn.address.number}{" "}
                        {companyLoggedIn.address.neighborhood}{" "}
                        {companyLoggedIn.address.cep}
                      </h3>
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
              <IonListHeader>
                <IonLabel color="primary">Sobre nós</IonLabel>
              </IonListHeader>
              <p className={"ion-margin ion-text-justify"}>
                {companyLoggedIn.about}
              </p>
              <IonList>
                <IonListHeader>
                  <IonLabel color="primary">Conforto</IonLabel>
                </IonListHeader>
                {amenities.map((amenity) => (
                  <IonItem lines={"none"} key={amenity.id}>
                    <IonLabel>{amenity.name}</IonLabel>
                    {!!companyLoggedIn.amenities!.find(
                      (companyAmenity) => companyAmenity.id === amenity.id
                    ) ? (
                      <IonIcon
                        title={`Possui ${amenity.name}`}
                        icon={checkmark}
                        color={"success"}
                      />
                    ) : (
                      <IonIcon
                        title={`Não possui ${amenity.name}`}
                        icon={close}
                        color={"danger"}
                      />
                    )}
                  </IonItem>
                ))}
              </IonList>
            </>
          )}

          {segmentActive === "comments" && (
            <RatingList companyId={userDecoded!.sub} />
          )}
        </Container>
      </IonContent>
    </IonPage>
  );
});

export default CompanyProfileTab;
