import React, { useState } from "react";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";

import CompanyDetailTab from "./CompanyDetailTab/CompanyDetailTab";
import { Discount } from "../../../../models/service.model";
import { IAmenityModel } from "../../../../models/amenity.model";
import SelectDiscountStep from "./CompanyListTab/FilterSteps/SelectDiscountStep";
import SelectTagStep from "./CompanyListTab/FilterSteps/SelectTagStep";
import CompanyListStep from "./CompanyListTab/FilterSteps/CompanyListStep";

export interface FilterState {
  searchText: string;
  tag?: string;
  discount?: Discount;
  priceRange?: number[];
  amenitiesSelected?: IAmenityModel[];
}

const HomeRoutes = () => {
  const [filter, setFilter] = useState<FilterState>({
    searchText: "",
  });

  return (
    <IonRouterOutlet>
      <Route exact path="/empresas/:id" component={CompanyDetailTab} />
      <Route
        exact
        path="/empresas"
        render={() => (
          <SelectDiscountStep filter={filter} setFilter={setFilter} />
        )}
      />
      <Route
        exact
        path="/empresas/desconto/:discount"
        render={() => <SelectTagStep filter={filter} setFilter={setFilter} />}
      />
      <Route
        path="/empresas/desconto/:discount/tag/:tag"
        render={() => <CompanyListStep filter={filter} setFilter={setFilter} />}
      />
    </IonRouterOutlet>
  );
};

export default HomeRoutes;
