import React from "react";
import { IonIcon } from "@ionic/react";
import { star } from "ionicons/icons";

const RatingStar: React.FC<{ rating: number }> = ({ rating }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <IonIcon icon={star} color={"warning"} style={{ marginRight: 4 }} />
    <b>{rating}</b>
  </div>
);

export default RatingStar;
