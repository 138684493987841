import { Instance, types } from "mobx-state-tree";

export interface IAmenityModel extends Instance<typeof AmenityModel> {}
export const AmenityModel = types.model({
  id: types.identifier,
  name: types.string,
});

export interface IAmenityCollection
  extends Instance<typeof AmenityCollection> {}
export const AmenityCollection = types.model({
  collection: types.array(AmenityModel),
});
