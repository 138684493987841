import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import { useTakeImage } from "../../../../utils/useTakeImage";
import { CreateCustomerSchema } from "./validation-schemas/create-customer.schema";
import ProfileCard from "./ProfileCard";
import { ICustomerModel } from "../../../../models/customer.model";
import TextField from "../../../../components/TextField";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import { CustomerToCreate } from "./ProfileRoutes";
import {Helmet} from "react-helmet";

const CustomerCreateForm: React.FC<
  RouteComponentProps & {
    customerToCreate: CustomerToCreate | null;
    setCustomerToCreate: Dispatch<SetStateAction<CustomerToCreate | null>>;
  }
> = observer(({ history, setCustomerToCreate, customerToCreate }) => {
  const { takePhoto } = useTakeImage();
  const {
    cityId,
    auth: { setAccessToken },
  } = useRootStore();

  const methods = useForm<any>({
    resolver: yupResolver(CreateCustomerSchema),
  });

  useEffect(() => {
    methods.register("avatar");
    if (customerToCreate) {
      if (customerToCreate.avatar) {
        methods.setValue("avatar", customerToCreate.avatar, {
          shouldDirty: true,
        });
      }

      if (customerToCreate.facebookId) {
        methods.register("facebookId");
        methods.setValue("facebookId", customerToCreate.facebookId, {
          shouldDirty: true,
        });
      }

      if (customerToCreate.googleId) {
        methods.register("googleId");
        methods.setValue("googleId", customerToCreate.googleId, {
          shouldDirty: true,
        });
      }

      if (customerToCreate.appleId) {
        methods.register("appleId");
        methods.setValue("appleId", customerToCreate.appleId, {
          shouldDirty: true,
        });
      }

      methods.setValue("name", customerToCreate.name, { shouldDirty: true });
      methods.register("email");
      methods.setValue("email", customerToCreate.email, { shouldDirty: true });
    }
    return () => {
      setCustomerToCreate(null);
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const onSubmit = async (customerForm: ICustomerModel) => {
    if (!customerForm.password) delete customerForm.password;
    if (cityId) customerForm.cityId = +cityId;
    setLoading(true);

    if (typeof customerForm.avatar === "string") {
      const response = await fetch(customerForm.avatar);
      customerForm.avatar = (await response.blob()) as any;
    }

    if(!customerForm.avatar) {
      delete customerForm.avatar
    }

    httpClient
      .post(
        "/customers",
        Object.keys(customerForm).reduce(
          (formData: FormData, currentKey: string) => {
            // @ts-ignore
            formData.append(currentKey, customerForm[currentKey]);
            return formData;
          },
          new FormData()
        )
      )
      .then((res) => {
        setAccessToken(res.data.accessToken).then(() => {
          setCustomerToCreate(null);
          history.replace("/perfil");
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonPage>
      <IonHeader>
        <Helmet title={"Cadastrar"} />
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonBackButton defaultHref={"/boas-vindas"} />
          </IonButtons>
          <IonTitle>Cadastro Usuário</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={loading} message={"Salvando..."} />
        <WrapperWithFooter>
          <Container>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ padding: 32 }}
              >
                <ProfileCard
                  onClick={() =>
                    takePhoto().then((blob) => methods.setValue("avatar", blob))
                  }
                  image={methods.watch("avatar")}
                  name={"Avatar"}
                />

                <TextField label={"Nome"} name={"name"} />

                <TextField label={"Email"} name={"email"} type={"email"} />

                <TextField
                  label={"CPF"}
                  name={"cpf"}
                  inputMode={"numeric"}
                  mask={"999.999.999-99"}
                />

                <TextField
                  label={"Telefone"}
                  name={"phone"}
                  type={"tel"}
                  mask={"(99) 99999-9999"}
                />

                <TextField
                  label={"Senha"}
                  name={"password"}
                  type={"password"}
                />
                <TextField
                  label={"Confirmar Senha"}
                  name={"passwordConfirmation"}
                  type={"password"}
                />

                <p>
                  Ao cadastrar você aceita os{" "}
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_API_URL}/politica_de_privacidade.pdf`}
                  >
                    termos de uso e política de privacidade
                  </a>
                </p>

                <IonButton type={"submit"} expand={"block"}>
                  Salvar
                </IonButton>
              </form>
            </FormProvider>
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default CustomerCreateForm;
