import React from "react";
import { IonLabel, IonRow, IonItem } from "@ionic/react";

import { Discount } from "../../../../../../models/service.model";

const DiscountFilter: React.FC<{
  setDiscount: (discount: Discount) => void;
}> = ({ setDiscount }) => (
  <>
    <IonRow className="ion-justify-content-center ion-align-items-center ion-wrap">
      <IonItem lines={"none"} onClick={() => setDiscount(Discount.HALF)} button>
        <IonLabel>
          <img
            src="/assets/images/50off.png"
            alt="Ver empresas com 50% de desconto"
          />
        </IonLabel>
      </IonItem>

      <IonItem lines={"none"} onClick={() => setDiscount(Discount.FULL)} button>
        <IonLabel>
          <img
            src="/assets/images/100off.png"
            alt="Ver empresas com 100% de desconto"
          />
        </IonLabel>
      </IonItem>
    </IonRow>
  </>
);

export default DiscountFilter;
