import React from "react";
import { useMediaQuery } from "react-responsive";

const Container: React.FC<{ style?: object }> = ({ children, style }) => {
  const isTabletOrUp = useMediaQuery({
    query: "(min-width: 768px)",
  });

  return (
    <div
      style={{
        maxWidth: isTabletOrUp ? "60%" : "none",
        margin: "auto",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
