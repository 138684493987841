import React, { useEffect, useMemo } from "react";
import {
  IonContent,
  IonPage,
  IonProgressBar,
  IonList,
  IonLabel,
  IonIcon,
  IonItem,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import {
  cardOutline,
  locateOutline,
  logOutOutline,
  readerOutline,
} from "ionicons/icons";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import ProfileCard from "./ProfileCard";
import Container from "../../../../components/Container";
import { Helmet } from "react-helmet";
import { usePlatform } from "../../../../utils/usePlatform";

export interface LinkProp {
  label: string;
  onClickProp?: { routerLink?: string; onClick?: () => void };
  icon?: string;
  iconURL?: string;
}

const ItemResponsive = styled(IonItem)`
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const CustomerProfileTab: React.FC<RouteComponentProps> = observer(
  ({ history }) => {
    const {
      setModalCities,
      auth: { userDecoded, setCustomer, customerLoggedIn, logout },
    } = useRootStore();

    useEffect(() => {
      if (userDecoded)
        httpClient
          .get(
            `/customers/${userDecoded.sub}?${RequestQueryBuilder.create({
              fields: ["id", "avatar", "name"],
            }).query()}`
          )
          .then((res) => {
            if (customerLoggedIn) {
              setCustomer({ ...customerLoggedIn, ...res.data });
            } else setCustomer(res.data);
          });
    }, [setCustomer, userDecoded]);

    const links: LinkProp[] = useMemo(
      () => [
        {
          icon: readerOutline,
          label: "Dados",
          onClickProp: {
            routerLink: `/perfil/editar`,
          },
        },
        {
          icon: cardOutline,
          label: "Cartões",
          onClickProp: {
            routerLink: `/perfil/cartoes`,
          },
        },
        {
          icon: locateOutline,
          label: "Localização",
          onClickProp: { onClick: () => setModalCities(true) },
        },
        {
          label: "SAC",
          onClickProp: { routerLink: "/perfil/sac" },
          iconURL: "/assets/icon/face-agent.svg",
        },
        {
          icon: logOutOutline,
          label: "Sair",
          onClickProp: { onClick: () => logout(history) },
        },
      ],
      [logout, setModalCities, history]
    );

    const platform = usePlatform();

    if (!customerLoggedIn) return <IonProgressBar type={"indeterminate"} />;
    return (
      <IonPage className={platform === "ios" ? "padding-notch" : ""}>
        <IonContent>
          <Helmet title={"Perfil"} />
          <Container>
            <div style={{ padding: 48 }}>
              <ProfileCard
                name={customerLoggedIn.name!}
                image={customerLoggedIn.avatar}
              />
              <IonList className={"ion-margin-top"}>
                {links.map((link) => (
                  <ItemResponsive
                    key={link.label}
                    {...link.onClickProp}
                    button={!!link.onClickProp?.onClick}
                    lines={"none"}
                    style={{ cursor: "pointer" }}
                  >
                    <IonIcon
                      color={"primary"}
                      icon={link.icon}
                      src={link.iconURL}
                      slot={"start"}
                    />
                    <IonLabel color={"secondary"}>{link.label}</IonLabel>
                  </ItemResponsive>
                ))}
              </IonList>
            </div>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
);

export default CustomerProfileTab;
