import { IonItem, IonLabel, IonList, IonProgressBar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

import RatingStar from "./RatingStar";
import {
  IRatingCollection,
  IRatingModel,
  RatingCollection,
} from "../models/rating.model";
import { httpClient } from "../utils/http-client";
import InfiniteScroll from "./InfiniteScroll";

const RatingList: React.FC<{ companyId: string }> = ({ companyId }) => {
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState<IRatingModel[]>([]);
  useEffect(() => {
    httpClient
      .get(
        `/ratings?${RequestQueryBuilder.create({
          page: 1,
          limit: 10,
          search: {
            "coupon.companyId": companyId,
          },
          sort: [{ field: "createdAt", order: "DESC" }],
          join: [
            { field: "coupon", select: ["id"] },
            { field: "coupon.customer", select: ["name", "id"] },
          ],
        }).query()}`
      )
      .then((res) => {
        setRatings(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId]);

  return (
    <>
      {!loading ? (
        ratings.length > 0 ? (
          <IonList>
            {ratings.map((rating) => (
              <IonItem key={rating.id}>
                <IonLabel>
                  <RatingStar rating={rating.score} />
                  <h2
                    style={{
                      fontWeight: "bold",
                      color: "var(--ion-color-secondary)",
                    }}
                  >
                    {rating.coupon!.customer!.name}
                  </h2>
                  <p className={"ion-text-wrap"}>{rating.message}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        ) : (
          <p className={"ion-margin"}>Não há avaliação disponível</p>
        )
      ) : (
        <IonProgressBar type={"indeterminate"} />
      )}
    </>
  );
};

export default RatingList;
