import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonRow,
  IonText,
} from "@ionic/react";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { close } from "ionicons/icons";
import { v4 } from "uuid";
//@ts-ignore
import IntlCurrencyInput from "react-intl-currency-input";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

import FlexContainer from "../../../../../../components/FlexContainer";
import { useRootStore } from "../../../../../../models/root-store/root-store.context";
import TextField from "../../../../../../components/TextField";
import IonItemOutlined from "../../../../../../components/IonItemOutlined";
import { IServiceModel } from "../../../../../../models/service.model";
import { httpClient } from "../../../../../../utils/http-client";
import { ISlotModel } from "../../../../../../models/slot.model";

const SectionTitle = styled(IonLabel)`
  font-weight: bold;
  color: var(--ion-color-secondary);
`;

const CompanyOperationForm: React.FC = observer(({ children }) => {
  const { tags, amenities } = useRootStore();

  const { register, errors, watch, setValue } = useFormContext();

  useEffect(() => {
    register("tags");
    register("amenities");
    register("services");
  }, []);

  const amenitiesSelected = watch("amenities");
  const tagsSelected = watch("tags");
  const services = watch("services");

  const deleteService = async (serviceField: IServiceModel) => {
    const { data: slots } = await httpClient.get<ISlotModel[]>(
      `/slots?${RequestQueryBuilder.create({
        join: [{ field: "service", select: ["id"] }],
        filter: [
          { field: "service.id", operator: "$eq", value: serviceField.id },
        ],
      }).query()}`
    );

    if (
      slots.length === 0 ||
      window.confirm(
        "Existem ofertas disponiveis para este procedimento, serão deletadas apenas as que não foram comprados ainda. Prosseguir?"
      )
    )
      setValue(
        "services",
        services.filter((service: any) => service.id !== serviceField.id),
        { shouldDirty: true }
      );
  };

  return (
    <FlexContainer direction={"column"} align={"stretch"}>
      <TextField label={"Horário de abertura"} name={"opensAt"} type={"time"} />
      <TextField
        label={"Horário de fechamento"}
        name={"closesAt"}
        type={"time"}
      />

      {amenities && (
        <>
          <IonListHeader>
            <SectionTitle>Características</SectionTitle>
          </IonListHeader>

          {amenities.map((amenity, index) => (
            <IonItem key={amenity.id}>
              <IonLabel>{amenity.name}</IonLabel>
              <IonCheckbox
                checked={
                  !!amenitiesSelected?.find(
                    (amenitySelected: any) => amenitySelected.id === amenity.id
                  )
                }
                onIonChange={(e) => {
                  if (e.detail.checked) {
                    setValue(
                      "amenities",
                      amenitiesSelected
                        ? [
                            ...amenitiesSelected,
                            { id: amenity.id, name: amenity.name },
                          ]
                        : [{ id: amenity.id, name: amenity.name }],
                      { shouldDirty: true }
                    );
                  } else {
                    setValue(
                      "amenities",
                      amenitiesSelected.filter(
                        (amenitySelected: any) =>
                          amenitySelected.id !== amenity.id
                      ),
                      { shouldDirty: true }
                    );
                  }
                }}
              >
                {amenity.name}
              </IonCheckbox>
            </IonItem>
          ))}
          {errors?.amenities && (
            <IonText color={"danger"}>
              <p>{errors?.amenities?.message}</p>
            </IonText>
          )}
        </>
      )}

      {tags && (
        <>
          <IonListHeader>
            <SectionTitle>Atuação</SectionTitle>
          </IonListHeader>

          {tags.map((tag) => (
            <IonItem key={tag.id}>
              <IonLabel>{tag.name}</IonLabel>
              <IonCheckbox
                checked={
                  !!tagsSelected?.find(
                    (tagSelected: any) => tagSelected.id === tag.id
                  )
                }
                onIonChange={(e) => {
                  if (e.detail.checked) {
                    setValue(
                      "tags",
                      tagsSelected
                        ? [...tagsSelected, { id: tag.id, name: tag.name }]
                        : [{ id: tag.id, name: tag.name }],
                      { shouldDirty: true }
                    );
                  } else {
                    setValue(
                      "tags",
                      tagsSelected?.filter(
                        (tagSelected: any) => tagSelected.id !== tag.id
                      ),
                      { shouldDirty: true }
                    );
                  }
                }}
              >
                {tag.name}
              </IonCheckbox>
            </IonItem>
          ))}
          {errors?.tags && (
            <IonText color={"danger"}>
              <p>{errors?.tags?.message}</p>
            </IonText>
          )}
        </>
      )}

      <IonListHeader>
        <SectionTitle>Procedimentos prestados no estabelecimento</SectionTitle>
      </IonListHeader>
      {services?.map((serviceField: any, index: number) => (
        <IonRow key={serviceField.id} className={"ion-align-items-center"}>
          <IonCol size="6">
            <IonItemOutlined>
              <IonLabel position={"floating"}>Nome do procedimento</IonLabel>
              <IonInput
                disabled={services[index].nameDisabled}
                value={services[index].name}
                onIonChange={(e) =>
                  setValue(
                    "services",
                    services.map((innerService: any) => {
                      if (innerService.id === serviceField.id) {
                        innerService.name = e.detail.value;
                      }

                      return innerService;
                    }),
                    { shouldDirty: true }
                  )
                }
              />
            </IonItemOutlined>
          </IonCol>
          <IonCol size="5">
            <IonItemOutlined className={"item-input item-has-value"}>
              <IonLabel position={"floating"}>
                Preço total do procedimento
              </IonLabel>
              <IntlCurrencyInput
                currency="BRL"
                config={{
                  locale: "pt-BR",
                  formats: {
                    number: {
                      BRL: {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    },
                  },
                }}
                style={{ border: 0, outline: 0 }}
                value={services[index].price}
                onChange={(event: InputEvent, value: number) => {
                  setValue(
                    "services",
                    services.map((innerService: any) => {
                      if (innerService.id === serviceField.id) {
                        innerService.price = value;
                      }

                      return innerService;
                    }),
                    { shouldDirty: true }
                  );
                }}
              />
            </IonItemOutlined>
          </IonCol>
          <IonCol size="1">
            <IonButton
              fill={"clear"}
              onClick={() => deleteService(serviceField)}
            >
              <IonIcon slot={"icon-only"} icon={close} />
            </IonButton>
          </IonCol>
        </IonRow>
      ))}
      <IonButton
        onClick={() =>
          setValue(
            "services",
            services
              ? [
                  ...services,
                  { id: v4(), name: "", price: 0, companyId: watch("id") },
                ]
              : [{ id: v4(), name: "", price: 0, companyId: watch("id") }],
            { shouldDirty: true }
          )
        }
        fill={"outline"}
      >
        Adicionar Mais Procedimento
      </IonButton>
      {errors?.services && (
        <IonText color={"danger"}>
          <p>
            {errors?.services
              ?.map((service: any) => {
                let message = "";

                if (service.name?.message)
                  message += service.name.message + "\n";

                if (service.price?.message) message += service.price?.message;
                return message;
              })
              .join("\n")}
          </p>
        </IonText>
      )}
      {children}
    </FlexContainer>
  );
});

export default CompanyOperationForm;
