import React from "react";
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import {
  addCircleOutline,
  cartOutline,
  idCard,
  settings,
  ticket,
} from "ionicons/icons";
import { useMediaQuery } from "react-responsive";

import ConfigRoutes from "./tabs/ConfigTab/ConfigRoutes";
import CompanyProfileRoutes from "./tabs/CompanyProfileTab/CompanyProfileRoutes";
import TicketsRoutes from "./tabs/TicketsTab/TicketsRoutes";
import CreateSlotRoutes from "./tabs/CreateSlotTab/CreateSlotRoutes";

const CompanyRoutes = () => {
  const isTabletOrUp = useMediaQuery({
    query: "(min-width: 768px)",
  });

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/tickets" component={TicketsRoutes} />
        <Route path="/criar-tickets" component={CreateSlotRoutes} />
        <Route path={"/perfil"} component={CompanyProfileRoutes} />
        <Route path={"/config"} component={ConfigRoutes} />
        <Redirect exact from="/" to="/tickets" />
      </IonRouterOutlet>

      <IonTabBar
        slot={isTabletOrUp ? "top" : "bottom"}
        style={{ height: isTabletOrUp ? "64px" : "58px" }}
        color={isTabletOrUp ? "light" : "secondary"}
      >
        {isTabletOrUp && (
          <IonTabButton
            tab="empresas"
            href="/tickets"
            style={{ marginRight: "auto" }}
          >
            <IonLabel>
              <img
                width={32}
                style={{ marginBottom: -8 }}
                src="/assets/images/LogotipoBeauticket.png"
                alt=""
              />
              <IonText color={"secondary"}>
                <p
                  style={{
                    display: "inline-block",
                    fontWeight: "bold",
                    fontFamily: "Vogue",
                    fontSize: 20,
                  }}
                >
                  EAUTICKET
                </p>
              </IonText>
            </IonLabel>
          </IonTabButton>
        )}

        <IonTabButton tab="tickets" href="/tickets">
          <IonIcon icon={ticket} />
          <IonLabel>Agendamentos</IonLabel>
        </IonTabButton>

        <IonTabButton tab="create-tickets" href="/criar-tickets">
          <IonIcon icon={cartOutline} />
          <IonLabel>Tickets à venda</IonLabel>
        </IonTabButton>

        <IonTabButton tab={"profile"} href={"/perfil"}>
          <IonIcon icon={idCard} />
          <IonLabel>Perfil</IonLabel>
        </IonTabButton>

        <IonTabButton tab={"settings"} href={"/config"}>
          <IonIcon icon={settings} />
          <IonLabel>Configurações</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default CompanyRoutes;
