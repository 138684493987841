import * as yup from "yup";
import { isValid } from "cpf";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const CreateCustomerSchema = yup.object().shape({
  name: yup.string().required(),
  phone: yup
    .string()
    .required()
    .test("is-phone", "Telefone inválido", (value) =>
      phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, "BR"), "BR")
    ),
  email: yup.string().email(),
  password: yup
    .string()
    .required()
    .min(6, "Senha deve ter 6 caracteres ou mais"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Senhas não conferem"),
  avatar: yup
    .mixed()
    .test("fileFormat", "Unsupported Format", (value) =>
      value && typeof value !== "string" ? value.type.includes("image") : true
    ),
  cpf: yup
    .string()
    .test(
      "is-cpf",
      "${path} não é válido",
      (value) => !!value && isValid(value)
    ),
});
