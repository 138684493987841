import jwtDecode from "jwt-decode";
import { Plugins } from "@capacitor/core";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { AxiosError } from "axios";

import { RootStore } from "./root.store";
import { httpClient } from "../../utils/http-client";
import { AuthModel, Entity, JwtPayload } from "../auth.model";
import setupFirebaseMessaging from "../../utils/setupFirebaseMessaging";

const { Storage } = Plugins;

export default async function setupRootStore(
  platform: "web" | "ios" | "android"
) {
  const { value: accessToken } = await Storage.get({ key: "accessToken" });
  const { value: cityId } = await Storage.get({ key: "cityId" });

  let userDecoded: JwtPayload | undefined;
  if (accessToken) {
    httpClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
    userDecoded = jwtDecode(accessToken);

    if (userDecoded && userDecoded.entity === Entity.COMPANY) {
      const res = await httpClient.get(
        `/companies/${userDecoded!.sub}?${RequestQueryBuilder.create({
          fields: ["approved"],
        }).query()}`
      );
      userDecoded!.approved = res.data.approved;
    }
  }

  const { data: amenities } = await httpClient.get("/amenities");
  const { data: tags } = await httpClient.get("/tags");

  const auth = AuthModel.create({ accessToken, userDecoded });
  httpClient.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        auth.logout();
      }
      throw error;
    }
  );

  const rootStore = RootStore.create({
    auth,
    cityId,
    modalCitiesOpen: !cityId,
    tags,
    amenities,
  });

  let fcmToken = await setupFirebaseMessaging(platform);
  if (!fcmToken && platform !== "web") {
    fcmToken = (await Storage.get({ key: "fcmToken" })).value as any;
  }
  if (fcmToken) {
    if (platform === "web") Storage.set({ key: "fcmToken", value: fcmToken });
    if (userDecoded) {
      const newDeviceToken: {
        token: string;
        deviceType: string;
        customerId?: string;
        companyId?: string;
      } = { token: fcmToken, deviceType: platform as any };

      if (userDecoded.entity === Entity.COMPANY) {
        newDeviceToken.companyId = userDecoded.sub;
      }

      if (userDecoded.entity === Entity.CUSTOMER) {
        newDeviceToken.customerId = userDecoded.sub;
      }

      httpClient.post("/tokens", newDeviceToken);
    }
  }

  return rootStore;
}
