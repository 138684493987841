import React, { useEffect, useRef } from "react";
import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import { documentText, exit, imageOutline, map, receipt } from "ionicons/icons";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import Container from "../../../../components/Container";
import { usePlatform } from "../../../../utils/usePlatform";

interface AppPage {
  onClickProp?: { routerLink?: string; onClick?: () => void };
  icon?: string;
  iconURL?: string;
  title: string;
}

const ItemResponsive = styled(IonItem)`
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const ConfigTab: React.FC<RouteComponentProps> = observer(({ history }) => {
  const {
    auth: { companyLoggedIn, setCompany, userDecoded, logout },
  } = useRootStore();

  useEffect(() => {
    httpClient
      .get(
        `/companies/${userDecoded!.sub}?${RequestQueryBuilder.create({
          fields: ["logo"],
        }).query()}`
      )
      .then((res) => {
        if (companyLoggedIn) {
          setCompany({ ...companyLoggedIn, ...res.data });
        } else {
          setCompany(res.data);
        }
      });
  }, []);

  const appPages = useRef<AppPage[]>([
    {
      title: "Dados Principais",
      onClickProp: { routerLink: "/config/editar/dados" },
      icon: documentText,
    },
    {
      title: "Endereço",
      onClickProp: { routerLink: "/config/editar/endereco" },
      icon: map,
    },
    {
      title: "Serviços",
      onClickProp: { routerLink: "/config/editar/servicos" },
      icon: receipt,
    },
    {
      title: "Galeria de fotos",
      onClickProp: { routerLink: "/config/editar/fotos" },
      icon: imageOutline,
    },
    {
      title: "SAC",
      onClickProp: { routerLink: "/config/sac" },
      iconURL: "/assets/icon/face-agent.svg",
    },
    {
      title: "Sair",
      onClickProp: { onClick: () => logout(history) },
      icon: exit,
    },
  ]);

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Configurações"} />
        <Container>
          <IonList style={{ padding: 32 }}>
            <IonItem lines={"none"}>
              <IonAvatar slot={"start"}>
                <img src={companyLoggedIn?.logo} alt="" />
              </IonAvatar>
              <IonLabel>
                <h2>{userDecoded!.name}</h2>
                <h3>{userDecoded!.email}</h3>
              </IonLabel>
            </IonItem>
            {appPages.current.map((appPage, index) => {
              return (
                <ItemResponsive
                  button={!!appPage.onClickProp?.onClick}
                  {...appPage.onClickProp}
                  lines="none"
                  key={appPage.title}
                >
                  <IonIcon
                    color={"primary"}
                    slot="start"
                    icon={appPage.icon}
                    src={appPage.iconURL}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </ItemResponsive>
              );
            })}
          </IonList>
        </Container>
      </IonContent>
    </IonPage>
  );
});

export default ConfigTab;
