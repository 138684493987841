import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

import { JwtPayload } from "../../../../../models/auth.model";
import { httpClient } from "../../../../../utils/http-client";
import { Discount, IServiceModel } from "../../../../../models/service.model";
import { currencyFormatter } from "../../../../../utils/currencyFormatter";
import { ISlotModel } from "../../../../../models/slot.model";

const now = new Date();

const ServicesList: React.FC<{
  companyId: string;
  userDecoded?: JwtPayload;
  setServiceSelected: (service: IServiceModel | null) => void;
}> = ({ userDecoded, companyId, setServiceSelected }) => {
  const [services, setServices] = useState<IServiceModel[]>([]);
  useEffect(() => {
    const initialQuery: any = {
      $and: [
        { companyId },
        { "slots.availableSlots": { $gt: 0 } },
        {
          "slots.startTime": {
            $gte: now.toISOString(),
          },
        },
      ],
    };

    const qb = RequestQueryBuilder.create({
      join: [
        {
          field: "slots",
          select: ["id", "discount", "availableSlots", "startTime"],
        },
      ],
      search: initialQuery,
    });

    httpClient.get(`/services?${qb.query()}`).then((res) => {
      setServices(res.data);
    });
  }, [userDecoded, companyId]);

  return (
    <>
      <IonList>
        {services.map((service: any) => {
          const [halfDiscountCount, fullDiscountCount] = service.slots
            ? [
                service.slots
                  .filter((slot: ISlotModel) => slot.discount == Discount.HALF)
                  .reduce(
                    (total: number, slot: ISlotModel) =>
                      total + slot.availableSlots,
                    0
                  ),
                service.slots
                  .filter((slot: ISlotModel) => slot.discount == Discount.FULL)
                  .reduce(
                    (total: number, slot: ISlotModel) =>
                      total + slot.availableSlots,
                    0
                  ),
              ]
            : [0, 0];

          return (
            <IonItem
              key={service.id}
              button
              onClick={() => {
                setServiceSelected(service);
              }}
            >
              <IonLabel>
                <h2>{service.name}</h2>
                <h3>{currencyFormatter.format(service.price)}</h3>
                {fullDiscountCount! > 0 && (
                  <p
                    style={{
                      display: "inline-block",
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: "var(--ion-color-primary)",
                      border: "1px solid var(--ion-color-primary)",
                      padding: "2px 4px",
                      borderRadius: 5,
                      marginTop: 8,
                      marginRight: 8,
                    }}
                  >
                    {fullDiscountCount} TICKET
                    {fullDiscountCount! > 1 && "S"} 100%
                  </p>
                )}
                {halfDiscountCount! > 0 && (
                  <p
                    style={{
                      display: "inline-block",
                      fontWeight: "bold",
                      color: "var(--ion-color-primary)",
                      border: "1px solid var(--ion-color-primary)",
                      padding: "2px 4px",
                      marginTop: 8,
                      borderRadius: 5,
                    }}
                  >
                    {halfDiscountCount} TICKET
                    {halfDiscountCount! > 1 && "S"} 50%
                  </p>
                )}
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </>
  );
};

export default ServicesList;
