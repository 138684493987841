import { useCamera } from "@ionic/react-hooks/camera";
import { CameraResultType, CameraSource } from "@capacitor/core";

export function useTakeImage() {
  const { getPhoto } = useCamera();

  const takePhoto = async () => {
    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      promptLabelPhoto: "Galeria",
      promptLabelPicture: "Câmera",
      promptLabelHeader: "Escolha um local",
      quality: 80,
    });
    const response = await fetch(cameraPhoto.webPath!);
    return await response.blob();
  };

  return {
    takePhoto,
  };
}
