import axios, { AxiosError } from "axios";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    if (error?.response?.status !== 404 && error?.response?.status !== 424) {
      const response = error?.response?.data;
      if (response && response.message) {
        if (Array.isArray(response.message)) {
          alert(response.message.join("\n"));
        } else alert(response.message);
      } else {
        alert("Ops, houve um erro mas ja estamos trabalhando para corrigir");
      }
    }
    return Promise.reject(error);
  }
);
