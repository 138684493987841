import React, { useEffect, useMemo, useState } from "react";
import {
  IonPage,
  IonContent,
  IonAvatar,
  IonItem,
  IonLabel,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton,
  IonButtons,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import Typography from "@material-ui/core/Typography";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import { ICouponModel } from "../../../../models/coupon.model";
import { calendar, person } from "ionicons/icons";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import { Helmet } from "react-helmet";
import { usePlatform } from "../../../../utils/usePlatform";

const TicketsTab = observer(() => {
  const {
    auth: { userDecoded },
  } = useRootStore();
  const [coupons, setCoupons] = useState<{
    [time: string]: ICouponModel[];
  }>({});
  useEffect(() => {
    const today = new Date();
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);

    httpClient
      .get(
        `/coupons?${RequestQueryBuilder.create({
          join: [
            { field: "customer", select: ["id", "avatar", "name"] },
            { field: "company", select: ["id"] },
          ],
          sort: [{ field: "bookDate", order: "ASC" }],
          filter: [
            {
              field: "companyId",
              operator: "$eq",
              value: userDecoded!.sub,
            },
            {
              field: "bookDate",
              operator: "$gte",
              value: today.toISOString(),
            },
          ],
        }).query()}`
      )
      .then((res) => {
        setCoupons(
          res.data.reduce((groupedByDate: any, coupon: ICouponModel) => {
            const bookDate = new Date(coupon.bookDate);
            const time = `${bookDate
              .getUTCHours()
              .toString()
              .padStart(2, "0")}:${bookDate
              .getUTCMinutes()
              .toString()
              .padStart(2, "0")}`;
            if (!groupedByDate[time]) {
              groupedByDate[time] = [];
            }
            groupedByDate[time].push(coupon);
            return groupedByDate;
          }, {} as any)
        );
      });
  }, [userDecoded]);

  const schedulesTime = useMemo(() => {
    return Object.keys(coupons);
  }, [coupons]);

  const redeemCoupon = (time: string, coupon: ICouponModel) => {
    if (
      window.confirm(
        "Deseja marcar cupom como resgatado? Confirme se o cliente compareceu no estabelecimento"
      )
    ) {
      httpClient
        .patch(`/coupons/${coupon.id}`, { redeemed: true })
        .then((res) => {
          const newCoupons = { ...coupons };
          newCoupons[time] = newCoupons[time].map((currentCoupon) => {
            if (coupon.id === currentCoupon.id) {
              currentCoupon.redeemed = true;
            }
            return currentCoupon;
          });
          setCoupons(newCoupons);
        });
    }
  };

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Agendamentos"} />
        <WrapperWithFooter>
          <Container>
            <IonToolbar>
              <IonTitle>Agendamentos</IonTitle>
              <IonButtons slot="end">
                <IonButton color="primary" routerLink="/tickets/calendario">
                  Calendário
                  <IonIcon icon={calendar} slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <Timeline style={{ paddingLeft: 0 }}>
              {schedulesTime.length > 0 ? (
                schedulesTime.map((time) => (
                  <TimelineItem key={time}>
                    <TimelineOppositeContent
                      style={{ flex: 0, paddingLeft: 8 }}
                    >
                      <Typography color="textSecondary">{time}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="secondary" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {coupons[time].map((coupon: ICouponModel) => (
                        <IonItem key={coupon.id}>
                          <IonLabel className="ion-text-wrap">
                            <IonAvatar slot="start">
                              {coupon.customer!.avatar ? (
                                <img src={coupon.customer!.avatar} alt="" />
                              ) : (
                                <IonIcon size={"large"} icon={person} />
                              )}
                            </IonAvatar>
                            <h2>{coupon.customer!.name}</h2>
                            <h3>Procedimento: {coupon.serviceName}</h3>
                            <p>
                              Cupom {Math.round(coupon.discount * 100)}%:{" "}
                              <strong>{coupon.token}</strong>
                            </p>
                            <p>
                              Valor do serviço no momento da compra:{" "}
                              {currencyFormatter.format(coupon.servicePrice)}
                            </p>
                            <p>
                              Valor à receber:{" "}
                              {currencyFormatter.format(
                                +coupon.servicePrice -
                                  coupon.servicePrice * coupon.discount
                              )}
                            </p>
                            <IonButton
                              disabled={coupon.redeemed}
                              fill={"clear"}
                              onClick={() => redeemCoupon(time, coupon)}
                            >
                              {coupon.redeemed
                                ? "Resgatado"
                                : "Marcar como resgatado"}
                            </IonButton>
                          </IonLabel>
                        </IonItem>
                      ))}
                    </TimelineContent>
                  </TimelineItem>
                ))
              ) : (
                <p className="ion-margin">Não há agendamento disponível</p>
              )}
            </Timeline>
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default TicketsTab;
