import * as yup from "yup";

export const CardSchema = yup.object().shape({
  cardNumber: yup.string().min(16).max(19).required(),
  brand: yup.string().required(),
  securityCode: yup.string().min(3).max(4).required(),
  holder: yup.string().required(),
  expirationDate: yup
    .string()
    .matches(/\d\d\/\d\d\d\d/)
    .test("is-expiration-date", "${path} data inválida", (value) => {
      if (!value) return false;

      const now = new Date();
      const [month, year] = value.split("/").map((part) => parseInt(part));

      if (month > 12 || month < 1 || year < now.getFullYear()) return false;
      return !(year === now.getFullYear() && month - 1 < now.getMonth());
    }),
});
