import React, { useState } from "react";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";

import CustomerProfileTab from "./CustomerProfileTab";
import Cards from "./Cards";
import CustomerCreateForm from "./CustomerCreateForm";
import CustomerEditForm from "./CustomerEditForm";
import Login from "./Login";
import CompanyCreateForm from "./CompanyCreateForm";
import SACPage from "../../../../components/SACPage";

export const ProfileAuthenticatedRoutes = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/perfil" component={CustomerProfileTab} />
      <Route path={"/perfil/cartoes"} component={Cards} />
      <Route path="/perfil/editar" component={CustomerEditForm} />
      <Route path="/perfil/sac" component={SACPage} />
    </IonRouterOutlet>
  );
};

export interface CustomerToCreate {
  name: string;
  email: string;
  avatar?: string;
  facebookId?: number;
  googleId?: number;
  appleId?: number;
}

export const ProfileUnauthenticatedRoutes = () => {
  const [
    customerToCreate,
    setCustomerToCreate,
  ] = useState<CustomerToCreate | null>(null);

  return (
    <IonRouterOutlet>
      <Route
        exact
        path="/boas-vindas"
        render={(props) => (
          <Login {...props} setCustomerToCreate={setCustomerToCreate} />
        )}
      />
      <Route
        path={"/boas-vindas/cliente"}
        render={(props) => (
          <CustomerCreateForm
            {...props}
            customerToCreate={customerToCreate}
            setCustomerToCreate={setCustomerToCreate}
          />
        )}
      />

      <Route path={"/boas-vindas/empresa"} component={CompanyCreateForm} />
    </IonRouterOutlet>
  );
};
