import React from "react";
import { observer } from "mobx-react-lite";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonProgressBar,
} from "@ionic/react";
import { trash } from "ionicons/icons";

import { ICardCollection, ICardModel } from "../../../../../models/card.model";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { httpClient } from "../../../../../utils/http-client";

interface CardsListProps {
  cards: ICardCollection | null;
}

const CardsList: React.FC<CardsListProps> = observer(({ cards }) => {
  const deleteCard = (card: ICardModel) => {
    if (window.confirm("Deseja excluir o cartão? Está ação é irreversível")) {
      const snapshot = getSnapshot(cards!);
      cards!.removeCard(card);
      httpClient.delete(`/cards/${card.id}`).catch((e) => {
        applySnapshot(cards!, snapshot);
        throw e;
      });
    }
  };

  return cards ? (
    <IonList>
      {cards.collection.length > 0 && (
        <IonListHeader>
          <IonLabel>Cartões cadastrados</IonLabel>
        </IonListHeader>
      )}
      {cards.collection.map((card) => (
        <IonItem key={card.id}>
          <IonLabel>**********{card.lastDigits}</IonLabel>
          <IonNote slot={"end"}>{card.brand}</IonNote>
          <IonButton
            fill={"clear"}
            slot={"end"}
            onClick={() => deleteCard(card)}
          >
            <IonIcon slot={"icon-only"} icon={trash} />
          </IonButton>
        </IonItem>
      ))}
    </IonList>
  ) : (
    <IonProgressBar type={"indeterminate"} />
  );
});

export default CardsList;
