import React, { useRef } from "react";
import styled from "styled-components";
import { timerOutline } from "ionicons/icons";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonText,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { Helmet } from "react-helmet";

import { useRootStore } from "../../models/root-store/root-store.context";
import { httpClient } from "../../utils/http-client";
import { usePlatform } from "../../utils/usePlatform";

const Grid = styled.div`
  display: grid;
  place-items: center;
  padding: 34px;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/assets/images/background.png");
`;

const PendingPage: React.FC<RouteComponentProps> = observer(({ history }) => {
  const refreshRef = useRef<HTMLIonRefresherElement | null>(null);
  const {
    auth: { logout, userDecoded },
  } = useRootStore();

  const doRefresh = () => {
    httpClient
      .get(
        `/companies/${userDecoded!.sub}?${RequestQueryBuilder.create({
          fields: ["approved"],
        }).query()}`
      )
      .then((res) => {
        userDecoded!.approved = res.data.approved;
        history.replace("/");
      })
      .finally(() => {
        refreshRef?.current?.complete();
      });
  };

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Aguarde"} />
        <IonRefresher ref={refreshRef} slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <IonIcon
              icon={timerOutline}
              color={"primary"}
              style={{ fontSize: 64 }}
            />
            <IonText color={"primary"}>
              <h1
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Exo 2', sans-serif",
                  textAlign: "center",
                }}
              >
                Seja bem vinda(o) aguarde a análise
              </h1>
            </IonText>
            <IonButton fill={"outline"} onClick={() => logout(history)}>
              Sair
            </IonButton>
          </div>
        </Grid>
      </IonContent>
    </IonPage>
  );
});

export default PendingPage;
