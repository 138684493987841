import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonPage,
  IonProgressBar,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FormProvider, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import TextField from "../../../../components/TextField";
import { httpClient } from "../../../../utils/http-client";
import { useTakeImage } from "../../../../utils/useTakeImage";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import { Photo } from "../../../../components/CompanyPhotosSlide";
import { trash } from "ionicons/icons";

const NewCompanyPhotoTab = observer(() => {
  const { takePhoto } = useTakeImage();
  const {
    auth: { userDecoded },
  } = useRootStore();

  const [submitting, setSubmitting] = useState(false);

  const [photos, setPhotos] = useState<Photo[] | null>(null);
  useEffect(() => {
    httpClient
      .get(`/company-photos?filter=companyId||$eq||${userDecoded!.sub}`)
      .then((res) => setPhotos(res.data));
  }, [userDecoded, submitting]);

  const methods = useForm();
  useEffect(() => {
    methods.register("url");
  }, []);

  const onSubmit = (newCompanyPhotoData: { url: Blob; legend: string }) => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append("url", newCompanyPhotoData.url);
    formData.append("legend", newCompanyPhotoData.legend);
    formData.append("companyId", userDecoded!.sub);
    httpClient
      .post(`/company-photos`, formData)
      .then(() => {
        methods.reset();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const galleryRef = useRef<any>(null);

  const legend = methods.watch("legend");
  const image = methods.watch("url");
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/config" />
          </IonButtons>
          <IonTitle>Galeria de fotos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Helmet title="Galeria de fotos" />
        <IonLoading isOpen={submitting} message="Salvando..." />
        <IonRow>
          <IonCol size="12" size-lg="4">
            <IonListHeader>
              <IonLabel>Nova foto</IonLabel>
            </IonListHeader>
            <FormProvider {...methods}>
              <form
                style={{ padding: 32 }}
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <TextField label="Legenda" name="legend" />

                <IonButton
                  onClick={() =>
                    takePhoto().then((newPhoto) => {
                      console.log(newPhoto);
                      methods.setValue("url", newPhoto);
                    })
                  }
                  fill="clear"
                  expand="full"
                >
                  Escolher Foto
                </IonButton>
                {image && (
                  <img
                    style={{ display: "block", margin: "auto" }}
                    height="300"
                    alt=""
                    src={URL.createObjectURL(image)}
                  />
                )}

                <IonButton
                  disabled={!image || !legend}
                  type="submit"
                  expand="full"
                >
                  Enviar
                </IonButton>
              </form>
            </FormProvider>
          </IonCol>

          <IonCol size="12" size-lg="8">
            <IonListHeader>
              <IonLabel>Fotos</IonLabel>
            </IonListHeader>
            {photos ? (
              photos.length > 0 ? (
                <Gallery
                  ref={galleryRef}
                  renderCustomControls={() => {
                    return (
                      <IonFab vertical="top" horizontal="end">
                        <IonButton
                          onClick={() => {
                            if (
                              window.confirm(
                                "Tem certeza que deseja remover a foto? Esta ação é irreversível"
                              )
                            ) {
                              const currentIndex = galleryRef.current.getCurrentIndex();
                              setSubmitting(true);
                              httpClient
                                .delete(
                                  `/company-photos/${photos[currentIndex].id}`
                                )
                                .finally(() => {
                                  setSubmitting(false);
                                });
                            }
                          }}
                        >
                          Excluir
                          <IonIcon icon={trash} slot="end" />
                        </IonButton>
                      </IonFab>
                    );
                  }}
                  showPlayButton={false}
                  items={
                    photos.map((photo) => ({
                      original: photo.url,
                      thumbnail: photo.url,
                      originalAlt: photo.legend,
                      thumbnailAlt: photo.legend,
                      originalTitle: photo.legend,
                      thumbnailTitle: photo.legend,
                      description: photo.legend,
                    })) as any
                  }
                />
              ) : (
                <p className="ion-padding">Não há fotos cadastradas</p>
              )
            ) : (
              <IonProgressBar type="indeterminate" />
            )}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
});

export default NewCompanyPhotoTab;
