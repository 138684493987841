import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonText,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { home, person, ticket } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "react-responsive";

import HomeRoutes from "./tabs/HomeTab/HomeRoutes";
import { useRootStore } from "../../models/root-store/root-store.context";
import { Entity } from "../../models/auth.model";
import CouponRoutes from "./tabs/CouponTab/CouponRoutes";
import {
  ProfileAuthenticatedRoutes,
  ProfileUnauthenticatedRoutes,
} from "./tabs/ProfileTab/ProfileRoutes";

const CustomerPageTabs: React.FC = observer(() => {
  const isTabletOrUp = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const {
    auth: { userDecoded },
  } = useRootStore();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/:tab(empresas)" component={HomeRoutes} />
        <Route
          path={"/:tab(boas-vindas)"}
          component={ProfileUnauthenticatedRoutes}
        />
        <Route
          path="/:tab(perfil)"
          render={(props) =>
            userDecoded ? (
              <ProfileAuthenticatedRoutes />
            ) : (
              <Redirect to="/boas-vindas" />
            )
          }
        />
        <Route path={"/:tab(cupons)"} component={CouponRoutes} />
        <Redirect exact from="/" to="/empresas" />
      </IonRouterOutlet>

      <IonTabBar
        slot={isTabletOrUp ? "top" : "bottom"}
        style={{ height: isTabletOrUp ? "64px" : "58px" }}
        color={isTabletOrUp ? "light" : "secondary"}
      >
        {isTabletOrUp && (
          <IonTabButton
            tab="empresas"
            href="/empresas"
            style={{ marginRight: "auto" }}
          >
            <IonLabel>
              <img
                width={32}
                style={{ marginBottom: -8 }}
                src="/assets/images/LogotipoBeauticket.png"
                alt=""
              />
              <IonText color={"secondary"}>
                <p
                  style={{
                    display: "inline-block",
                    fontWeight: "bold",
                    fontFamily: "Vogue",
                    fontSize: 20,
                  }}
                >
                  EAUTICKET
                </p>
              </IonText>
            </IonLabel>
          </IonTabButton>
        )}

        <IonTabButton tab="empresas" href="/empresas">
          <IonIcon icon={home} />
          <IonLabel>Início</IonLabel>
        </IonTabButton>

        {userDecoded?.entity === Entity.CUSTOMER && (
          <IonTabButton tab={"cupons"} href={"/cupons"}>
            <IonIcon icon={ticket} />
            <IonLabel>Tickets</IonLabel>
          </IonTabButton>
        )}

        {userDecoded ? (
          <IonTabButton tab="perfil" href="/perfil">
            <IonIcon icon={person} />
            <IonLabel>Perfil</IonLabel>
          </IonTabButton>
        ) : (
          <IonTabButton tab="boas-vindas" href="/boas-vindas">
            <IonIcon icon={person} />
            <IonLabel>Perfil</IonLabel>
          </IonTabButton>
        )}
      </IonTabBar>
    </IonTabs>
  );
});

export default CustomerPageTabs;
