import { Instance, types } from "mobx-state-tree";

export enum Brand {
  VISA = "Visa",
  MASTER = "Master",
  AMEX = "Amex",
  ELO = "Elo",
  AURA = "Aura",
  JCB = "JCB",
  DINERS = "Diners",
  DISCOVER = "Discover",
}

export interface ICardModel extends Instance<typeof CardModel> {}
export const CardModel = types.model({
  id: types.identifier,
  brand: types.string,
  securityCode: types.string,
  lastDigits: types.number,
});

export interface ICardCollection extends Instance<typeof CardCollection> {}
export const CardCollection = types
  .model({ collection: types.array(CardModel) })
  .actions((self) => ({
    addCard(card: ICardModel) {
      self.collection.push(card);
    },
    removeCard(card: ICardModel) {
      self.collection.remove(card);
    },
  }));
