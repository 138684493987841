import React, { useState } from "react";
import {
  IonButton,
  IonLabel,
  IonItem,
  IonModal,
  IonPage,
  IonTextarea,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonRow,
  IonContent,
} from "@ionic/react";
import Rating from "react-rating";
import { star, starOutline } from "ionicons/icons";
import { httpClient } from "../../../../utils/http-client";
import { ICouponModel } from "../../../../models/coupon.model";
import IonItemOutlined from "../../../../components/IonItemOutlined";

export interface NewRatingModalProps {
  isOpen: boolean;
  closeModal: () => void;
  coupon: ICouponModel;
}

const NewRatingModal: React.FC<NewRatingModalProps> = (props) => {
  const [form, setForm] = useState({
    score: 5,
    message: "",
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    httpClient
      .post("/ratings", { ...form, couponId: props.coupon!.id })
      .then((res) => {
        props.closeModal();
      });
  };

  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.closeModal}>
      <IonPage>
        <IonContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              paddingTop: 32,
            }}
          >
            <h1 className={"ion-text-center ion-margin-vertical"}>
              Avalie o serviço do cupom {props.coupon?.token}
            </h1>
            <form
              onSubmit={onSubmit}
              style={{
                flex: 1,
                display: "grid",
                placeItems: "center",
                width: "100%",
              }}
            >
              <Rating
                initialRating={form.score}
                emptySymbol={<IonIcon size={"large"} icon={starOutline} />}
                fullSymbol={
                  <IonIcon size={"large"} icon={star} color={"warning"} />
                }
                onChange={(score) => setForm({ ...form, score })}
              />

              <IonItemOutlined>
                <IonLabel position={"floating"}>Comentário</IonLabel>
                <IonTextarea
                  rows={5}
                  value={form.message}
                  onIonChange={(e) =>
                    setForm({ ...form, message: e.detail.value! })
                  }
                />
              </IonItemOutlined>

              <IonRow>
                <IonButton
                  onClick={() => {
                    props.closeModal();
                  }}
                  fill={"clear"}
                >
                  Cancelar
                </IonButton>
                <IonButton type={"submit"}>Enviar</IonButton>
              </IonRow>
            </form>
          </div>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default NewRatingModal;
