import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonProgressBar } from "@ionic/react";

import "swiper/swiper.min.css";
import "react-image-lightbox/style.css";

export interface Photo {
  legend: string;
  url: string;
  id: string;
}

const CompanyPhotoSlide: React.FC<{
  photos?: Photo[];
}> = ({ photos }) => {
  const [selectedImage, setSelectedImage] = useState<Photo | null>(null);
  return photos ? (
    <>
      <Swiper
        slidesPerView="auto"
        navigation
        spaceBetween={8}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        {photos.map((photo) => (
          <SwiperSlide
            key={photo.id}
            style={{ marginLeft: 8, width: "auto", cursor: "pointer" }}
            onClick={() => setSelectedImage(photo)}
          >
            <img src={photo.url} alt={photo.legend} height="150" />
          </SwiperSlide>
        ))}
      </Swiper>
      {selectedImage && (
        <Lightbox
          mainSrc={selectedImage.url}
          nextSrc={
            photos[
              photos.findIndex((photo) => photo.id === selectedImage.id) + 1
            ]?.url
          }
          prevSrc={
            photos[
              photos.findIndex((photo) => photo.id === selectedImage.id) - 1
            ]?.url
          }
          onMovePrevRequest={() =>
            setSelectedImage(
              photos[
                photos.findIndex((photo) => photo.id === selectedImage.id) - 1
              ]
            )
          }
          onMoveNextRequest={() =>
            setSelectedImage(
              photos[
                photos.findIndex((photo) => photo.id === selectedImage.id) + 1
              ]
            )
          }
          imageCaption={selectedImage.legend}
          onCloseRequest={() => setSelectedImage(null)}
        />
      )}
    </>
  ) : (
    <IonProgressBar type="indeterminate" />
  );
};

export default CompanyPhotoSlide;
