import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonPage,
  IonProgressBar,
  IonToast,
  setupConfig,
} from "@ionic/react";
import { Plugins } from "@capacitor/core";

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./theme/variables.css";
import "./theme/fonts.css";
import "./theme/global.css";

import "./utils/translateYup";
import setupRootStore from "./models/root-store/setup-root-store";
import { IRootStore } from "./models/root-store/root.store";
import { RootStoreProvider } from "./models/root-store/root-store.context";
import LocationModal from "./components/LocationModal";
import Routes from "./Routes";
import { Helmet } from "react-helmet";

const { SplashScreen, Device } = Plugins;

setupConfig({
  mode: "md",
});

const App = () => {
  const [platform, setPlatform] = useState<"ios" | "android" | "web" | null>(
    null
  );
  useEffect(() => {
    Device.getInfo().then((device) => setPlatform(device.platform as any));
  }, []);

  const [rootStore, setRootStore] = useState<IRootStore | null>(null);
  useEffect(() => {
    if (platform)
      setupRootStore(platform)
        .then(setRootStore)
        .finally(() => {
          SplashScreen.hide();
        });
  }, [platform]);

  if (!rootStore) return <IonProgressBar type="indeterminate" />;
  return (
    <RootStoreProvider value={rootStore}>
      <IonApp>
        <Helmet titleTemplate={"%s | Beauticket"} defaultTitle={"Beauticket"} />
        <Routes />

        <LocationModal />

        <IonToast
          isOpen={!!rootStore.toastMessage}
          position={"bottom"}
          onDidDismiss={() => rootStore.setToast("")}
          message={rootStore.toastMessage}
          duration={2000}
        />
      </IonApp>
    </RootStoreProvider>
  );
};

export default App;
