import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { add, ticket, trash } from "ionicons/icons";
import { observer } from "mobx-react-lite";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { Helmet } from "react-helmet";
import { DayModifiers } from "react-day-picker";

import { httpClient } from "../../../../utils/http-client";
import { ISlotModel } from "../../../../models/slot.model";
import NewSlotModal from "./NewSlotModal";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import Container from "../../../../components/Container";
import Calendar from "../../../../components/Calendar";
import { usePlatform } from "../../../../utils/usePlatform";
import { Discount } from "../../../../models/service.model";

const now = new Date();

const CreateSlotTab = observer(() => {
  const {
    auth: { userDecoded },
  } = useRootStore();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [available100offDays, setAvailable100offDays] = useState<Date[]>([]);
  const [available50offDays, setAvailable50offDays] = useState<Date[]>([]);
  useEffect(() => {
    const qb = RequestQueryBuilder.create({
      join: [
        { field: "service", select: ["id"] },
        { field: "service.company", select: ["id"] },
      ],
      fields: ["startTime", "discount"],
      filter: [
        { field: "service.company", operator: "$eq", value: userDecoded!.sub },
      ],
    });

    httpClient.get(`/slots?${qb.query()}`).then((res) => {
      let _available50offDays: Date[] = [];
      let _available100offDays: Date[] = [];

      res.data.forEach((slot: ISlotModel) => {
        if (slot.discount == Discount.HALF) {
          _available50offDays.push(new Date(slot.startTime));
        } else {
          _available100offDays.push(new Date(slot.startTime));
        }
      });

      setAvailable100offDays([..._available100offDays]);
      setAvailable50offDays([..._available50offDays]);
    });
  }, [userDecoded, createModalOpen]);

  const [services, setServices] = useState([]);
  useEffect(() => {
    httpClient
      .get(
        `/services?${RequestQueryBuilder.create({
          filter: [
            { field: "companyId", operator: "$eq", value: userDecoded!.sub },
          ],
        }).query()}`
      )
      .then((res) => setServices(res.data));
  }, [userDecoded]);

  const [loading, setLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [slots, setSlots] = useState<ISlotModel[]>([]);
  useEffect(() => {
    setLoading(true);
    const selectedDayStartTime = new Date(selectedDay.getTime());
    selectedDayStartTime.setUTCHours(0);
    selectedDayStartTime.setUTCMinutes(0);
    selectedDayStartTime.setUTCSeconds(0);

    const selectedDayEndTime = new Date(selectedDay.getTime());
    selectedDayEndTime.setUTCHours(23);
    selectedDayEndTime.setUTCMinutes(59);
    selectedDayEndTime.setUTCSeconds(59);

    httpClient
      .get(
        `/slots?${RequestQueryBuilder.create({
          join: [
            { field: "service", select: ["id", "name"] },
            { field: "service.company", select: ["id"] },
          ],
          sort: [{ field: "startTime", order: "ASC" }],
          filter: [
            {
              field: "service.company.id",
              operator: "$eq",
              value: userDecoded!.sub,
            },
            {
              field: "startTime",
              operator: "$between",
              value: [
                selectedDayStartTime.toISOString(),
                selectedDayEndTime.toISOString(),
              ],
            },
          ],
        }).query()}`
      )
      .then((res) => {
        setSlots(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedDay, createModalOpen]);

  const deleteSlot = (slotId: string) => {
    if (window.confirm("Excluir cupom? Está ação é irreversível.")) {
      setLoading(true);
      httpClient
        .delete(`/slots/${slotId}`)
        .then(() => setSlots(slots.filter((slot) => slot.id !== slotId)))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <Helmet title={"Adicionar Oferta"} />
      <IonContent>
        <Container style={{ paddingBottom: 68 }}>
          <IonToolbar>
            <IonButtons slot={"start"}>
              <IonButton fill={"clear"} routerLink={"/tickets"}>
                <IonIcon slot={"start"} icon={ticket} />
                Agendamentos
              </IonButton>
            </IonButtons>
            <IonTitle>Tickets à venda</IonTitle>
          </IonToolbar>
          <Calendar
            onDayClick={(day: Date, modifiers: DayModifiers) => {
              day.setHours(now.getHours());
              day.setMinutes(now.getMinutes());
              day.setSeconds(now.getSeconds());
              day.setMilliseconds(now.getMilliseconds());
              setSelectedDay(day);
            }}
            currentMonth={now}
            available50offDays={available50offDays}
            available100offDays={available100offDays}
            selectedDay={selectedDay}
          />
          {selectedDay.getTime() >= now.getTime() && (
            <IonButton fill="clear" onClick={() => setCreateModalOpen(true)}>
              Nova oferta
              <IonIcon icon={add} slot="end" />
            </IonButton>
          )}

          {loading ? (
            <IonSpinner
              name={"dots"}
              className={"ion-margin-top"}
              style={{ marginLeft: "50%", transform: "translateX(-50%)" }}
            />
          ) : slots.length > 0 ? (
            <IonList>
              <IonListHeader>
                <IonLabel>Ofertas criadas</IonLabel>
              </IonListHeader>
              {slots.map((slot) => (
                <IonItem key={slot.id}>
                  <IonLabel>
                    <h2>
                      {slot.service!.name} {Math.round(slot.discount * 100)}%
                    </h2>
                    <h3>{new Date(slot.startTime).getHours()}h</h3>
                    <p>Quantidade restante {slot.availableSlots}</p>
                  </IonLabel>
                  <IonButton onClick={() => deleteSlot(slot.id)} slot={"end"}>
                    <IonIcon icon={trash} />
                  </IonButton>
                </IonItem>
              ))}
            </IonList>
          ) : (
            <p className={"ion-margin"}>Nenhuma oferta criada</p>
          )}
        </Container>
        <NewSlotModal
          onDidDismiss={(slot) => {
            setCreateModalOpen(false);
          }}
          date={selectedDay}
          services={services}
          setOpen={setCreateModalOpen}
          isOpen={createModalOpen}
        />
      </IonContent>
    </IonPage>
  );
});

export default CreateSlotTab;
