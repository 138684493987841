import React, { useEffect, useState } from "react";
import { IonText } from "@ionic/react";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { useFormContext } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

import { SelectOptions } from "./Select";

export interface AutocompleteProps {
  label: string;
  name: string;
  options: SelectOptions[];
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: red[700],
    },
  },
});

const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  name,
  options,
}) => {
  const { register, errors, setValue, getValues } = useFormContext();
  useEffect(() => {
    register(name);
  }, []);

  const [autoComplete, setAutocomplete] = useState<SelectOptions | null>(
    getValues(name)
      ? options.find((option) => option.value == getValues(name)) || null
      : null
  );
  const [inputValue, setInputValue] = useState("");
  return (
    <ThemeProvider theme={theme}>
      <MuiAutocomplete
        value={autoComplete}
        noOptionsText="Nada encontrado"
        options={options}
        onChange={(_, option) => {
          setAutocomplete(option);
          if (option)
            setValue(name, option.value.toString(), { shouldDirty: true });
        }}
        getOptionLabel={(option) => option.label}
        style={{
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 10,
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            label={label}
            {...params}
            style={{
              paddingLeft: 16,
              paddingTop: 3,
              paddingBottom: 7,
            }}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
      {errors && errors[name] && (
        <IonText color={"danger"}>
          <p>{errors[name]?.message}</p>
        </IonText>
      )}
    </ThemeProvider>
  );
};

export default Autocomplete;
