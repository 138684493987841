import { Instance, types } from "mobx-state-tree";
import { SlotModel } from "./slot.model";

export enum Discount {
  HALF = 0.5,
  FULL = 1.0,
}

export interface IServiceModel extends Instance<typeof ServiceModel> {}
//@ts-ignore
export const ServiceModel = types.model({
  id: types.string,
  companyId: types.string,
  name: types.string,
  price: types.number,
});
