import React, { useEffect } from "react";
import { IonLabel, IonText, IonTextarea } from "@ionic/react";

import IonItemOutlined from "./IonItemOutlined";
import { useFormContext } from "react-hook-form";

export interface TextAreaProps {
  label: string;
  name: string;
}

const TextArea: React.FC<TextAreaProps> = ({ name, label }) => {
  const { register, watch, setValue, errors } = useFormContext();
  useEffect(() => {
    register(name);
  }, []);

  return (
    <>
      <IonItemOutlined>
        <IonLabel position={"floating"}>{label}</IonLabel>
        <IonTextarea
          name={name}
          value={watch(name)}
          onIonChange={(e) =>
            setValue(name, e.detail.value!, {
              shouldDirty: true,
            })
          }
        />
      </IonItemOutlined>

      {errors && errors[name] && (
        <IonText color={"danger"}>
          <p>{errors[name]?.message}</p>
        </IonText>
      )}
    </>
  );
};

export default TextArea;
