import { flow, Instance, types } from "mobx-state-tree";
import jwtDecode from "jwt-decode";
import { Plugins } from "@capacitor/core";

import { CompanyModel, ICompanyModel } from "./company.model";
import { CustomerModel, ICustomerModel } from "./customer.model";
import { httpClient } from "../utils/http-client";

export enum Entity {
  COMPANY = "company",
  CUSTOMER = "customer",
}

export interface JwtPayload {
  name: string;
  email: string;
  entity: Entity;
  sub: string;
  approved?: boolean;
}

const { Storage, FacebookLogin, GoogleAuth } = Plugins;

export interface IAuthModel extends Instance<typeof AuthModel> {}
export const AuthModel = types
  .model({
    accessToken: types.maybeNull(types.string),
    companyLoggedIn: types.maybe(CompanyModel),
    customerLoggedIn: types.maybe(CustomerModel),
    userDecoded: types.maybe(
      types.custom<string, JwtPayload>({
        name: "UserDecoded",
        fromSnapshot(value: string) {
          return JSON.parse(value) as JwtPayload;
        },
        toSnapshot(value: JwtPayload) {
          return JSON.stringify(value);
        },
        isTargetType(value: string | JwtPayload): boolean {
          return typeof value !== "string" && value && "entity" in value;
        },
        getValidationMessage(snapshot: string): string {
          if (snapshot?.includes("entity|name|age|email")) return "";
          return "Payload inválido";
        },
      })
    ),
  })
  .actions((self) => ({
    setAccessToken: flow(function* (accessToken: string) {
      yield Storage.set({ key: "accessToken", value: accessToken });
      self.accessToken = accessToken;
      self.userDecoded = jwtDecode(accessToken);
      httpClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
    }),
    setUserDecoded(newUser: any) {
      self.userDecoded = newUser;
    },
    setCustomer(customer: ICustomerModel) {
      self.customerLoggedIn = customer;
    },
    setCompany(company: ICompanyModel) {
      self.companyLoggedIn = company;
    },
    logout: flow(function* (history?: any) {
      const { value: fcmToken } = yield Storage.get({ key: "fcmToken" });
      if (self.userDecoded) {
        httpClient.post("/tokens/delete-by-condition", {
          [self.userDecoded!.entity === Entity.COMPANY
            ? "companyId"
            : "customerId"]: self.userDecoded!.sub,
          token: fcmToken as any,
        });
        self.accessToken = null;
        self.userDecoded = undefined;
        if (history) history.replace("/boas-vindas");
        self.companyLoggedIn = undefined;
        self.customerLoggedIn = undefined;
        delete httpClient.defaults.headers.common["Authorization"];
        yield Storage.remove({ key: "accessToken" });
        FacebookLogin.logout().catch(() => {});
        GoogleAuth.signOut().catch(() => {});
      }
    }),
  }));
