import "react-day-picker/lib/style.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DayModifiers } from "react-day-picker";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import { observer } from "mobx-react-lite";
import { cart } from "ionicons/icons";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import Calendar from "../../../../../components/Calendar";
import { httpClient } from "../../../../../utils/http-client";
import { ISlotModel } from "../../../../../models/slot.model";
import { Discount, IServiceModel } from "../../../../../models/service.model";
import { ICouponModel } from "../../../../../models/coupon.model";
import { ICardModel } from "../../../../../models/card.model";
import { currencyFormatter } from "../../../../../utils/currencyFormatter";
import { JwtPayload } from "../../../../../models/auth.model";
import NewCardForm from "../../ProfileTab/components/NewCardForm";

const now = new Date();
const BuyCoupons: React.FC<{
  userDecoded?: JwtPayload;
  serviceSelected: IServiceModel;
  setServiceSelected: (service: IServiceModel | null) => void;
}> = observer(({ userDecoded, serviceSelected, setServiceSelected }) => {
  const [alreadyBought100Discount, setAlreadyBought100Discount] = useState(
    false
  );
  useEffect(() => {
    if (userDecoded) {
      httpClient
        .get(
          `/services?${RequestQueryBuilder.create({
            join: [
              {
                field: "customersThatGot100Discount",
                select: ["id", "alreadyGot100Discount"],
              },
            ],
            filter: [
              {
                field: "customersThatGot100Discount.alreadyGot100Discount",
                operator: "$eq",
                value: true,
              },
              {
                field: "customersThatGot100Discount.customerId",
                operator: "$eq",
                value: userDecoded.sub,
              },
              { field: "id", operator: "$eq", value: serviceSelected.id },
            ],
          }).query()}`
        )
        .then(({ data: services }: { data: any[] }) => {
          if (services.length > 0) {
            setAlreadyBought100Discount(true);
          }
        });
    }
  }, [serviceSelected, userDecoded]);

  const history = useHistory();
  const isTabletOrUp = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const [shoppingCart, setShoppingCart] = useState<ICouponModel[]>([]);

  const calendarWrapperRef = useRef<HTMLDivElement | null>(null);
  const [selectedDay, setSelectedDay] = useState<Date>(now);
  const onDayClick = (
    day: Date,
    modifiers: DayModifiers = {} as DayModifiers,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (modifiers.off50 || modifiers.off100) {
      if (shoppingCart.length > 0) {
        if (
          window.confirm(
            "Você só pode adicionar serviços do mesmo dia no carrinho, se mudar ele será esvaziado. Deseja prosseguir?"
          )
        ) {
          setShoppingCart([]);
          setSelectedDay(day);
        }
      } else {
        setSelectedDay(day);
      }
    }
  };

  const [available100offDays, setAvailable100offDays] = useState<Date[]>([]);
  const [available50offDays, setAvailable50offDays] = useState<Date[]>([]);
  useEffect(() => {
    const qb = RequestQueryBuilder.create({
      fields: ["startTime", "discount"],
      filter: [
        { field: "serviceId", operator: "$eq", value: serviceSelected.id },
        { field: "availableSlots", operator: "$gt", value: 0 },
        {
          field: "startTime",
          operator: "$gte",
          value: `${now.getUTCFullYear()}-${now
            .getMonth()
            .toString()
            .padStart(2, "0")}-${now
            .getDate()
            .toString()
            .padStart(2, "0")} ${now
            .getHours()
            .toString()
            .padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`,
        },
      ],
    });

    if (alreadyBought100Discount) {
      qb.setFilter({
        field: "discount",
        operator: "$eq",
        value: Discount.HALF,
      });
    }

    httpClient.get(`/slots?${qb.query()}`).then((res) => {
      let _available50offDays: Date[] = [];
      let _available100offDays: Date[] = [];

      res.data.forEach((slot: ISlotModel) => {
        if (slot.discount == Discount.HALF) {
          _available50offDays.push(new Date(slot.startTime));
        } else {
          _available100offDays.push(new Date(slot.startTime));
        }
      });

      setAvailable100offDays([..._available100offDays]);
      setAvailable50offDays([..._available50offDays]);
    });
  }, [serviceSelected, alreadyBought100Discount]);

  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState<{ [time: string]: ISlotModel[] }>({});
  useEffect(() => {
    setLoading(true);
    const selectedDayStartTime = new Date(selectedDay.getTime());
    selectedDayStartTime.setUTCHours(0);
    selectedDayStartTime.setUTCMinutes(0);
    selectedDayStartTime.setUTCSeconds(0);

    const selectedDayEndTime = new Date(selectedDay.getTime());
    selectedDayEndTime.setUTCHours(23);
    selectedDayEndTime.setUTCMinutes(59);
    selectedDayEndTime.setUTCSeconds(59);

    const qb = RequestQueryBuilder.create({
      join: [{ field: "service", select: ["id", "name", "price"] }],
      sort: [{ field: "startTime", order: "ASC" }],
      filter: [
        { field: "service.id", operator: "$eq", value: serviceSelected.id },
        { field: "availableSlots", operator: "$gt", value: 0 },
        {
          field: "startTime",
          operator: "$between",
          value: [
            selectedDayStartTime.toISOString(),
            selectedDayEndTime.toISOString(),
          ],
        },
      ],
    });

    if (alreadyBought100Discount) {
      qb.setFilter({
        field: "discount",
        operator: "$eq",
        value: Discount.HALF,
      });
    }

    httpClient
      .get(`/slots?${qb.query()}`)
      .then((res) => {
        setSlots(
          res.data.reduce((groupedByDate: any, slot: ISlotModel) => {
            const startDate = new Date(slot.startTime);
            const time = `${startDate
              .getUTCHours()
              .toString()
              .padStart(
                2,
                "0"
              )}:${startDate.getUTCMinutes().toString().padStart(2, "0")}`;
            if (!groupedByDate[time]) {
              groupedByDate[time] = [];
            }
            groupedByDate[time].push(slot);
            return groupedByDate;
          }, {} as any)
        );
        setTimeout(() => {
          if (calendarWrapperRef.current)
            calendarWrapperRef.current.scrollIntoView();
        }, 1);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedDay, alreadyBought100Discount]);

  const schedulesTime = useMemo(() => {
    return Object.keys(slots);
  }, [slots]);

  const [cardId, setCardId] = useState<string | null>(null);
  const [cards, setCards] = useState<ICardModel[]>([]);
  useEffect(() => {
    if (userDecoded)
      httpClient
        .get(
          `/cards?${RequestQueryBuilder.create({
            filter: [
              {
                field: "customerId",
                operator: "$eq",
                value: userDecoded.sub,
              },
            ],
          }).query()}`
        )
        .then((res) => {
          if (cards.length > 0) {
            setCardId(res.data[0].id);
          }
          setCards(res.data);
        });
  }, [userDecoded?.sub]);

  const buyCoupons = () => {
    if (
      window.confirm(
        "Você está adquirindo o ticket com direito ao desconto, o valor do procedimento deverá ser pago no estabelecimento no dia e hora agendada."
      )
    ) {
      setLoading(true);
      httpClient
        .post("/coupons/bulk", { bulk: shoppingCart, cardId })
        .then((res) => {
          setShoppingCart([]);
          history.push("/cupons");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const totalToPay = useMemo(() => {
    return shoppingCart.reduce((total: number, coupon: ICouponModel) => {
      return total + coupon.price;
    }, 0);
  }, [shoppingCart]);

  const [newCardModalOpen, setNewCardModalOpen] = useState(false);

  return (
    <>
      <IonToolbar>
        <IonButtons slot={"start"} style={{ marginTop: 6 }}>
          <IonButton onClick={() => setServiceSelected(null)}>Voltar</IonButton>
        </IonButtons>
        <IonTitle>Procedimento: {serviceSelected.name}</IonTitle>
      </IonToolbar>
      <div
        ref={calendarWrapperRef}
        style={{
          display: "flex",
          flexDirection: isTabletOrUp ? "row" : "column",
        }}
      >
        <Calendar
          onDayClick={onDayClick}
          currentMonth={now}
          available50offDays={available50offDays}
          available100offDays={available100offDays}
          selectedDay={selectedDay}
        />
        {loading ? (
          <IonSpinner
            name={"dots"}
            className={"ion-margin-top"}
            style={{ marginLeft: "50%", transform: "translateX(-50%)" }}
          />
        ) : (
          <div style={{ width: "100%" }}>
            <IonListHeader className={"ion-margin-start"}>
              <IonLabel className={"ion-text-wrap"}>
                <p style={{ width: "80%" }}>
                  Obs: O agendamento do ticket é em horário da cidade local
                </p>
              </IonLabel>
            </IonListHeader>
            <Timeline>
              {schedulesTime.length > 0 ? (
                schedulesTime.map((time: string) => (
                  <TimelineItem key={time}>
                    <TimelineOppositeContent style={{ flex: 0 }}>
                      <Typography color="textSecondary">{time}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="secondary" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {slots[time].map((slot: ISlotModel) => {
                        const inShoppingCart = !!shoppingCart.find(
                          (coupon) => coupon.slotId === slot.id
                        );

                        const now = new Date();

                        const bookTime = new Date(selectedDay.getTime());
                        const [hours, minutes] = time.split(":");
                        bookTime.setHours(+hours);
                        bookTime.setMinutes(+minutes);

                        const disabled = bookTime.getTime() < now.getTime();

                        return (
                          <IonItem
                            button
                            color={inShoppingCart ? "primary" : undefined}
                            key={slot.id}
                            disabled={disabled}
                            onClick={() => {
                              if (!userDecoded) {
                                return history.push("/perfil/entrar");
                              }
                              if (!inShoppingCart) {
                                if (
                                  slot.discount == Discount.FULL &&
                                  !!shoppingCart.find(
                                    (coupon) => coupon.discount == Discount.FULL
                                  )
                                ) {
                                  return alert(
                                    "Você só pode escolher um cupom de 100%"
                                  );
                                }

                                const newCoupon: ICouponModel = {} as ICouponModel;
                                const bookDate = new Date(
                                  selectedDay.getTime()
                                );
                                const [hours, minutes] = time.split(":");
                                bookDate.setUTCHours(+hours);
                                bookDate.setUTCMinutes(+minutes);
                                bookDate.setUTCSeconds(0);
                                newCoupon.bookDate = bookDate.toISOString();
                                newCoupon.slotId = slot.id;
                                newCoupon.companyId = serviceSelected.companyId;
                                newCoupon.discount = slot.discount;
                                newCoupon.price =
                                  slot.discount == Discount.HALF
                                    ? slot.service!.price * 0.05
                                    : slot.service!.price * 0.1;

                                setShoppingCart([...shoppingCart, newCoupon]);
                              } else {
                                setShoppingCart(
                                  shoppingCart.filter(
                                    (coupon) => coupon.slotId !== slot.id
                                  )
                                );
                              }
                            }}
                          >
                            <IonLabel>
                              <h2>{slot.service!.name}</h2>
                              <h3>{Math.round(slot.discount * 100)}% OFF</h3>
                              <p>
                                De{" "}
                                {currencyFormatter.format(slot.service!.price)}{" "}
                                por{" "}
                                {currencyFormatter.format(
                                  slot.service!.price -
                                    slot.service!.price * slot.discount
                                )}
                              </p>
                              <p>Vagas {slot.availableSlots}</p>
                              <p className={"ion-text-wrap"}>
                                Valor do agendamento:{" "}
                                {currencyFormatter.format(
                                  slot.discount == Discount.HALF
                                    ? slot.service!.price * 0.05
                                    : slot.service!.price * 0.1
                                )}
                              </p>
                              {disabled && (
                                <p>
                                  <strong>EXPIRADO</strong>
                                </p>
                              )}
                            </IonLabel>
                            <IonIcon slot={"end"} icon={cart} color={"light"} />
                          </IonItem>
                        );
                      })}
                    </TimelineContent>
                  </TimelineItem>
                ))
              ) : (
                <p className="ion-margin">Não há ticket disponível</p>
              )}
            </Timeline>
            {shoppingCart.length > 0 && (
              <>
                <IonButton
                  onClick={() => setNewCardModalOpen(true)}
                  fill={"clear"}
                  expand={"block"}
                >
                  Cadastrar Cartão
                </IonButton>
                <IonItem className={"ion-margin-horizontal"}>
                  <IonLabel>Cartão</IonLabel>
                  <IonSelect
                    value={cardId}
                    onIonChange={(e) => setCardId(e.detail.value!)}
                    cancelText={"Cancelar"}
                    okText={"SELECIONAR CARTÃO"}
                  >
                    {cards.map((card) => (
                      <IonSelectOption key={card.id} value={card.id}>
                        **********{card.lastDigits}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonButton
                  ref={(ref: HTMLIonButtonElement) => {
                    if (ref) {
                      setTimeout(() => ref.scrollIntoView(), 1);
                    }
                  }}
                  onClick={buyCoupons}
                  disabled={!cardId}
                  className={"ion-margin-horizontal"}
                  expand={"block"}
                >
                  Finalizar Compra de {currencyFormatter.format(totalToPay)}
                </IonButton>
              </>
            )}
          </div>
        )}
        <IonModal
          isOpen={newCardModalOpen}
          onDidDismiss={() => setNewCardModalOpen(false)}
        >
          <IonPage>
            <IonContent>
              <div style={{ padding: 24 }}>
                <NewCardForm
                  closeModal={() => setNewCardModalOpen(false)}
                  selectCard={(card: ICardModel) => {
                    setCards([...cards, card]);
                    setCardId(card.id);
                  }}
                />
              </div>
            </IonContent>
          </IonPage>
        </IonModal>
      </div>
    </>
  );
});

export default BuyCoupons;
