import styled from "styled-components";

const CompanyDetailsHeader = styled.header<{ banner?: string }>`
  height: 135px;
  width: 100%;
  background: ${(props) =>
      props.banner
        ? `linear-gradient(
              to right,
              #000304 0%,
              rgba(92, 58, 83, 0.3) 100%
            ),url("${props.banner}")`
        : "none"}
    var(--ion-color-secondary);
  background-size: cover;
`;

export default CompanyDetailsHeader;
