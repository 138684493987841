import React, { useEffect } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route, useHistory } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";

import { Entity } from "./models/auth.model";
import PendingPage from "./pages/CompanyPages/PendingPage";
import CustomerPageTabs from "./pages/CustomerPages/CustomerPageTabs";
import { observer } from "mobx-react-lite";
import { useRootStore } from "./models/root-store/root-store.context";
import CompanyRoutes from "./pages/CompanyPages/CompanyRoutes";
import { Plugins, PushNotification } from "@capacitor/core";

const { PushNotifications, Device } = Plugins;

const Routes = observer(() => {
  const {
    auth: { userDecoded },
  } = useRootStore();

  const history = useHistory();
  useEffect(() => {
    Device.getInfo().then((info) => {
      if (info.platform !== "web") {
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotification) => {
            if (notification.data?.routerLink) {
              history.push(notification.data.routerLink);
            }
          }
        );
      }
    });
  }, []);

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route
          path={"/"}
          render={(props) => {
            return userDecoded?.entity === Entity.COMPANY ? (
              userDecoded.approved ? (
                <CompanyRoutes />
              ) : (
                <PendingPage {...props} />
              )
            ) : (
              <CustomerPageTabs />
            );
          }}
        />
      </IonRouterOutlet>
    </IonReactRouter>
  );
});

export default Routes;
