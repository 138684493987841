import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonProgressBar,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import Rating from "react-rating";
import { Plugins } from "@capacitor/core";
import { Link } from "react-router-dom";
import { eyeOff, eye, star, starOutline } from "ionicons/icons";

import { ICouponModel } from "../../../../models/coupon.model";
import { httpClient } from "../../../../utils/http-client";
import { useRootStore } from "../../../../models/root-store/root-store.context";
import InfiniteScroll from "../../../../components/InfiniteScroll";
import NewRatingModal from "./NewRatingModal";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import Container from "../../../../components/Container";
import { Helmet } from "react-helmet";
import { usePlatform } from "../../../../utils/usePlatform";

const { Device } = Plugins;
const now = new Date();
now.setUTCHours(0);
now.setUTCMinutes(0);
now.setUTCSeconds(0);

const CouponTab = observer(() => {
  const {
    auth: { userDecoded },
  } = useRootStore();
  const infiniteScrollEl = useRef<HTMLIonInfiniteScrollElement | null>(null);
  const [showExpiredCoupons, setShowExpiredCoupons] = useState(false);
  const [couponsStatus, setCouponsStatus] = useState("pending");
  const [coupons, setCoupons] = useState<ICouponModel[]>([]);
  const [couponToRate, setCouponToRate] = useState<ICouponModel | null>(null);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const qb = RequestQueryBuilder.create({
      join: [
        { field: "customer", select: ["id"] },
        { field: "company", select: ["id", "name", "logo"] },
        { field: "rating", select: ["id", "score", "message"] },
      ],
      limit: 10,
      page,
      sort: [{ field: "bookDate", order: "DESC" }],
      filter: [
        { field: "customer.id", operator: "$eq", value: userDecoded!.sub },
        {
          field: "redeemed",
          operator: "$eq",
          value: couponsStatus === "redeemed",
        },
      ],
    });

    if (couponsStatus !== "redeemed" && !showExpiredCoupons) {
      qb.setFilter({
        field: "bookDate",
        operator: "$gte",
        value: now.toISOString(),
      });
    }

    httpClient
      .get(`/coupons?${qb.query()}`)
      .then((res) => {
        if (res.data.data.length < 10) {
          if (infiniteScrollEl?.current)
            infiniteScrollEl.current.disabled = true;
        }

        if (page > 1) {
          return setCoupons([...coupons, ...res.data.data]);
        }

        setCoupons(res.data.data);
      })
      .finally(() => {
        if (infiniteScrollEl?.current) infiniteScrollEl.current.complete();
      });
  }, [userDecoded, couponsStatus, page, couponToRate, showExpiredCoupons]);

  useEffect(() => {
    setPage(1);
  }, [couponsStatus]);

  const platform = usePlatform();

  return (
    <IonPage className={platform === "ios" ? "padding-notch" : ""}>
      <IonContent>
        <Helmet title={"Tickets"} />
        <Container>
          <IonToolbar>
            <IonTitle>Reservas</IonTitle>
          </IonToolbar>
          <IonSegment
            value={couponsStatus}
            onIonChange={(e) => setCouponsStatus(e.detail.value!)}
          >
            <IonSegmentButton value="pending">
              <IonLabel>Pendentes</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="redeemed">
              <IonLabel>Utilizadas</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {couponsStatus !== "redeemed" && (
            <IonToolbar>
              <IonItem>
                <IonCheckbox
                  slot="start"
                  checked={showExpiredCoupons}
                  onIonChange={(e) => {
                    setPage(1);
                    setShowExpiredCoupons(!showExpiredCoupons);
                  }}
                />
                <IonLabel>
                  {showExpiredCoupons ? "Ocultar" : "Exibir"} tickets expirados
                </IonLabel>
              </IonItem>
            </IonToolbar>
          )}

          <div className={"ion-padding"}>
            {coupons ? (
              coupons.length > 0 ? (
                <>
                  <IonList>
                    <IonListHeader>
                      <IonLabel>
                        Apresente o{" "}
                        <IonText color={"secondary"}>
                          <b>código</b>
                        </IonText>{" "}
                        no estabelecimento e efetue o pagamento do{" "}
                        <IonText color={"secondary"}>
                          <b>valor do serviço</b>
                        </IonText>
                      </IonLabel>
                    </IonListHeader>
                    {coupons.map((coupon: any) => {
                      const bookDate = new Date(coupon.bookDate);
                      return (
                        <IonItem key={coupon.id}>
                          <IonLabel>
                            {coupon.redeemed && coupon.rating && (
                              <Rating
                                readonly
                                initialRating={coupon.rating.score}
                                emptySymbol={<IonIcon icon={starOutline} />}
                                fullSymbol={
                                  <IonIcon icon={star} color={"warning"} />
                                }
                              />
                            )}
                            <IonText
                              color={
                                bookDate.getTime() > now.getTime()
                                  ? "primary"
                                  : "medium"
                              }
                            >
                              <p>
                                <strong>
                                  Data agendada:{" "}
                                  {`${bookDate.getUTCFullYear()}/${bookDate
                                    .getUTCMonth()
                                    .toString()
                                    .padStart(
                                      2,
                                      "0"
                                    )}/${bookDate
                                    .getUTCDate()
                                    .toString()
                                    .padStart(
                                      2,
                                      "0"
                                    )} ${bookDate
                                    .getUTCHours()
                                    .toString()
                                    .padStart(
                                      2,
                                      "0"
                                    )}:${bookDate
                                    .getUTCMinutes()
                                    .toString()
                                    .padStart(2, "0")}`}
                                </strong>
                              </p>
                            </IonText>
                            <h2
                              className={"ion-text-wrap"}
                              style={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              Procedimento:{" "}
                              <IonText color={"primary"}>
                                {coupon.serviceName}
                              </IonText>
                            </h2>
                            <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
                              Desconto: {Math.round(coupon.discount * 100)}%
                            </h3>
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Código:
                              <IonText color={"secondary"}>
                                {coupon.token}
                              </IonText>
                            </p>
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Valor total:{" "}
                              <IonText color={"secondary"}>
                                {currencyFormatter.format(coupon.servicePrice)}
                              </IonText>
                            </p>
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Valor com {Math.floor(coupon.discount * 100)}% de
                              desconto:{" "}
                              <IonText color={"secondary"}>
                                {currencyFormatter.format(
                                  coupon.servicePrice -
                                    coupon.servicePrice * coupon.discount
                                )}
                              </IonText>
                            </p>
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Valor do agendamento:{" "}
                              {currencyFormatter.format(coupon.price)}
                            </p>
                            <p>
                              Compra realizada dia:{" "}
                              {new Date(coupon.createdAt).toLocaleString()}
                            </p>

                            <Link to={`/empresas/${coupon.company.id}`}>
                              Salão: {coupon.company.name}
                            </Link>

                            {!coupon.rating && coupon.redeemed && (
                              <IonButton
                                fill={"clear"}
                                onClick={() => setCouponToRate(coupon)}
                              >
                                Avaliar
                              </IonButton>
                            )}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                  </IonList>
                  <InfiniteScroll
                    infiniteScrollEl={infiniteScrollEl}
                    nextPage={() => setPage(page + 1)}
                  />
                </>
              ) : (
                <IonButton
                  fill={"outline"}
                  routerLink={"/empresas"}
                  className={"ion-text-wrap"}
                >
                  Não há, encontre o cupom ideal para você
                </IonButton>
              )
            ) : (
              <IonProgressBar type={"indeterminate"} />
            )}
            <NewRatingModal
              isOpen={!!couponToRate}
              closeModal={() => setCouponToRate(null)}
              coupon={couponToRate!}
            />
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
});

export default CouponTab;
