import React from "react";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";

import ConfigTab from "./ConfigTab";
import EditBasic from "./EditBasic";
import EditAddress from "./EditAddress";
import EditServices from "./EditServices";
import SACPage from "../../../../components/SACPage";
import NewCompanyPhotoTab from "./NewCompanyPhotoTab";

const ConfigRoutes = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/config" component={ConfigTab} />
      <Route path="/config/editar/dados" component={EditBasic} />
      <Route path="/config/editar/endereco" component={EditAddress} />
      <Route path="/config/editar/servicos" component={EditServices} />
      <Route path="/config/editar/fotos" component={NewCompanyPhotoTab} />
      <Route path="/config/sac" component={SACPage} />
    </IonRouterOutlet>
  );
};

export default ConfigRoutes;
