import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { PhoneNumberUtil } from "google-libphonenumber";

import { useRootStore } from "../models/root-store/root-store.context";
import Container from "./Container";
import TextArea from "./TextArea";
import TextField from "./TextField";
import WrapperWithFooter from "./WrapperWithFooter";
import { httpClient } from "../utils/http-client";
import { Entity } from "../models/auth.model";

const phoneUtil = PhoneNumberUtil.getInstance();

export const sacFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  email: yup.string().required("Email obrigatório"),
  phone: yup
    .string()
    .nullable()
    .test(
      "is-phone",
      "Telefone inválido",
      (value) =>
        !value ||
        phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, "BR"), "BR")
    ),
  message: yup.string().required(),
});

const SACPage = observer(() => {
  const {
    auth: { userDecoded },
  } = useRootStore();

  const methods = useForm({ resolver: yupResolver(sacFormSchema) });

  const history = useHistory();
  const [sendingForm, setSendingForm] = useState(false);
  const onSubmit = (formData: yup.InferType<typeof sacFormSchema>) => {
    setSendingForm(true);
    httpClient
      .post("/sac", formData)
      .then((res) => {
        history.replace(
          userDecoded!.entity == Entity.COMPANY ? "/config" : "/perfil"
        );
      })
      .finally(() => {
        setSendingForm(false);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={
                userDecoded!.entity == Entity.COMPANY ? "/config" : "/perfil"
              }
            />
          </IonButtons>
          <IonTitle>Atendimento ao cliente</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={sendingForm} message="Enviando..." />
        <WrapperWithFooter>
          <Container>
            <FormProvider {...methods}>
              <form
                style={{ padding: 32 }}
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <TextField
                  initialValue={userDecoded?.name || ""}
                  name="name"
                  label="Nome"
                />
                <TextField
                  initialValue={userDecoded?.email || ""}
                  name="email"
                  label="Email"
                  type="email"
                />
                <TextField
                  name="phone"
                  label="Telefone"
                  type={"tel"}
                  mask={"(99) 99999-9999"}
                />
                <TextArea name="message" label="mensagem" />

                <IonButton type="submit" expand="full">
                  Enviar
                </IonButton>
              </form>
            </FormProvider>
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default SACPage;
