import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as cnpj from "cnpj";

const phoneUtil = PhoneNumberUtil.getInstance();

export const CompanyBasicSchema = yup.object().shape({
  logo: yup.mixed().required("Logomarca obrigatória"),
  name: yup.string().required("Nome obrigatório"),
  cnpj: yup
    .string()
    .required("CNPJ obrigatório")
    .test("is-cnpj", "CNPJ invalido", (value) => {
      return !!value && cnpj.validate(value);
    }),
  email: yup.string().required().email("Email inválido"),
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "Senha deve ter 6 caracteres ou mais"),
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Senhas não conferem"),
  phone: yup
    .string()
    .required("Telefone obrigatório")
    .test("is-phone", "Telefone inválido", (value) =>
      phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, "BR"), "BR")
    ),
  landline: yup
    .string()
    .nullable()
    .test("is-phone", "Telefone inválido", (value) => !!value &&
      phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, "BR"), "BR")
    ),
  about: yup.string().required("Sobre a empresa obrigatório"),
  facebookUrl: yup.string().nullable().url("Facebook inválido"),
  instagram: yup.string().nullable().url("Instagram inválido"),
  website: yup.string().nullable().url("Site inválido"),
  banner: yup.mixed().required("Banner obrigatório"),
});
