import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonContent,
  IonTitle,
  IonButtons,
  IonButton,
  IonLoading,
  IonProgressBar,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import { useTakeImage } from "../../../../utils/useTakeImage";
import { EditCustomerSchema } from "./validation-schemas/edit-customer.schema";
import ProfileCard from "./ProfileCard";
import { ICustomerModel } from "../../../../models/customer.model";
import { dirtyFormValues } from "../../../../utils/getDirtyFormValues";
import TextField from "../../../../components/TextField";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import {Helmet} from "react-helmet";

const CustomerEditForm: React.FC<RouteComponentProps> = observer(
  ({ history }) => {
    const { takePhoto } = useTakeImage();
    const {
      cityId,
      auth: { customerLoggedIn, setCustomer, userDecoded },
    } = useRootStore();

    const methods = useForm<ICustomerModel>({
      resolver: yupResolver(EditCustomerSchema),
    });

    const [loadingCurrentData, setLoadingCurrentData] = useState(true);
    useEffect(() => {
      httpClient
        .get(`/customers/${userDecoded!.sub}?`)
        .then((res) => {
          Object.entries(res.data).forEach(([key, value]) => {
            if (key && value) {
              methods.register(key);
              methods.setValue(key, value);
            }
          });
        })
        .finally(() => {
          setLoadingCurrentData(false);
        });
    }, [userDecoded, setCustomer]);

    useEffect(() => {
      methods.register("avatar");
    }, []);

    const { dirtyFields, isDirty } = methods.formState;
    const [loading, setLoading] = useState(false);
    const onSubmit = (customerForm: ICustomerModel) => {
      if (!customerForm.password) delete customerForm.password;
      if (cityId) customerForm.cityId = +cityId;
      setLoading(true);
      const dirtyValues = dirtyFormValues(dirtyFields, methods.getValues());
      httpClient
        .patch(
          `/customers/${userDecoded!.sub}`,
          Object.keys(dirtyValues).reduce(
            (formData: FormData, currentKey: string) => {
              // @ts-ignore
              formData.append(currentKey, dirtyValues[currentKey]);
              return formData;
            },
            new FormData()
          )
        )
        .then((res) => {
          setCustomer(res.data);

          history.replace("/perfil");
        })
        .finally(() => setLoading(false));
    };

    return (
      <IonPage>
        <IonHeader>
            <Helmet title={"Editar Cadastro"} />
          <IonToolbar>
            <IonButtons slot={"start"}>
              <IonBackButton defaultHref={"/perfil"} />
            </IonButtons>
            <IonTitle>Atualizar Cadastro</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonLoading isOpen={loading} message={"Salvando..."} />

          <WrapperWithFooter>
            <Container>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  style={{ padding: 32 }}
                >
                  {loadingCurrentData ? (
                    <IonProgressBar type="indeterminate" />
                  ) : (
                    <>
                      <ProfileCard
                        onClick={() =>
                          takePhoto().then((blob) =>
                            methods.setValue("avatar", blob, {
                              shouldDirty: true,
                            })
                          )
                        }
                        image={
                          methods.watch("avatar") || customerLoggedIn?.avatar
                        }
                        name={"Avatar"}
                      />

                      <TextField label={"Nome"} name={"name"} />

                      <TextField
                        label={"Email"}
                        name={"email"}
                        type={"email"}
                      />

                      <TextField
                        label={"CPF"}
                        disabled
                        name={"cpf"}
                        inputMode={"numeric"}
                        mask={"999.999.999-99"}
                      />

                      <TextField
                        label={"Telefone"}
                        name={"phone"}
                        type={"tel"}
                        mask={"(99) 99999-9999"}
                      />

                      <TextField
                        label={"Senha"}
                        name={"password"}
                        type={"password"}
                      />

                      <TextField
                        label={"Confirmar Senha"}
                        name={"passwordConfirmation"}
                        type={"password"}
                      />
                    </>
                  )}

                  <IonButton type="submit" expand={"block"} disabled={!isDirty}>
                    Salvar
                  </IonButton>
                </form>
              </FormProvider>
            </Container>
          </WrapperWithFooter>
        </IonContent>
      </IonPage>
    );
  }
);

export default CustomerEditForm;
