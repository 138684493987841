import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FormProvider, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { RouteComponentProps } from "react-router-dom";

import { useRootStore } from "../../../../models/root-store/root-store.context";
import { httpClient } from "../../../../utils/http-client";
import { dirtyFormValues } from "../../../../utils/getDirtyFormValues";
import { CompanyOperationsSchema } from "../../../CustomerPages/tabs/ProfileTab/validation-schemas/company/company-operations.schema";
import CompanyOperationForm from "../../../CustomerPages/tabs/ProfileTab/components/SlidesCompanyForm/CompanyOperationForm";
import Container from "../../../../components/Container";
import WrapperWithFooter from "../../../../components/WrapperWithFooter";
import {Helmet} from "react-helmet";

const EditBasic: React.FC<RouteComponentProps> = observer(({ history }) => {
  const {
    auth: { userDecoded },
  } = useRootStore();

  const methods = useForm({ resolver: yupResolver(CompanyOperationsSchema) });
  const { formState } = methods;

  const [loadingCurrentData, setLoadingCurrentData] = useState(true);
  useEffect(() => {
    httpClient
      .get(
        `/companies/${userDecoded!.sub}?${RequestQueryBuilder.create({
          fields: ["id", "opensAt", "closesAt"],
          join: [
            ["tags", ["id", "name"]],
            ["amenities", ["id", "name"]],
            ["services", ["id", "price", "name", "companyId"]],
          ],
        }).query()}`
      )
      .then((res) => {
        methods.register("tags");
        methods.setValue("tags", res.data.tags, { shouldDirty: false });
        methods.register("amenities");
        methods.setValue("amenities", res.data.amenities, {
          shouldDirty: false,
        });
        methods.register("services");
        methods.setValue(
          "services",
          res.data.services.map((service: any) => ({
            ...service,
            nameDisabled: true,
          })),
          { shouldDirty: false }
        );
        methods.register("opensAt");
        methods.setValue("opensAt", res.data.opensAt.slice(0, 5));
        methods.register("closesAt");
        methods.setValue("closesAt", res.data.closesAt.slice(0, 5));
      })
      .finally(() => {
        setLoadingCurrentData(false);
      });
  }, [userDecoded]);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = (formValues: any) => {
    const dirtyValues: any = dirtyFormValues(formState.dirtyFields, formValues);
    if (Object.keys(dirtyValues).length > 0) {
      setSubmitting(true);
      httpClient
        .patch(`/companies/${userDecoded!.sub}`, dirtyValues)
        .then((res) => {
          setSubmitting(false);
          history.replace("/config");
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <Helmet title={'Editar Serviços'}/>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <IonBackButton defaultHref={"/config"} />
          </IonButtons>
          <IonTitle>Serviços</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={submitting} message="Salvando..." />
        <WrapperWithFooter>
          <Container>
            <div style={{ padding: 24 }}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {loadingCurrentData ? (
                    <IonProgressBar type="indeterminate" />
                  ) : (
                    <CompanyOperationForm />
                  )}
                  <IonButton
                    disabled={!formState.isDirty}
                    className="ion-margin-top"
                    expand={"block"}
                    type={"submit"}
                  >
                    Salvar
                  </IonButton>
                </form>
              </FormProvider>
            </div>
          </Container>
        </WrapperWithFooter>
      </IonContent>
    </IonPage>
  );
});

export default EditBasic;
