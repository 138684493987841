import { Instance, types } from "mobx-state-tree";

export interface ICustomerModel extends Instance<typeof CustomerModel> {}
export const CustomerModel = types.model({
  id: types.string,
  avatar: types.union(types.undefined, types.null, types.string),
  name: types.union(types.undefined, types.null, types.string),
  phone: types.union(types.undefined, types.null, types.string),
  email: types.union(types.undefined, types.null, types.string),
  cpf: types.union(types.undefined, types.null, types.string),
  cityId: types.union(types.undefined, types.null, types.number),
  password: types.maybe(types.string),
});
