import { Instance, types } from "mobx-state-tree";
import { Plugins } from "@capacitor/core";

import { TagModel } from "../tag.model";
import { AuthModel } from "../auth.model";
import { AmenityModel } from "../amenity.model";

const { Storage } = Plugins;

export const RootStore = types
  .model({
    cityId: types.maybeNull(types.string),
    modalCitiesOpen: false,
    tags: types.array(TagModel),
    amenities: types.array(AmenityModel),
    toastMessage: types.maybe(types.string),
    auth: AuthModel,
  })
  .actions((self) => ({
    setTags(tags: any) {
      self.tags = tags;
    },
    setAmenities(amenities: any) {
      self.amenities = amenities;
    },
    setModalCities(open: boolean) {
      self.modalCitiesOpen = open;
    },
    setToast(message: string) {
      self.toastMessage = message;
    },
    setCityId(cityId: string) {
      self.cityId = cityId;
      Storage.set({ key: "cityId", value: cityId });
    },
  }));

export interface IRootStore extends Instance<typeof RootStore> {}
