import React, { useEffect, useMemo, useRef, useState } from "react";
import { IonButton, IonIcon, IonInput, IonLabel, IonText } from "@ionic/react";
import { useFormContext } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import MuiTextField from "@material-ui/core/TextField";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import { eye, eyeOff } from "ionicons/icons";

import IonItemOutlined from "./IonItemOutlined";

export interface TextFieldProps {
  label: string;
  name: string;
  mask?: string;
  unmask?: boolean;
  initialValue?: string;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  type?:
    | "number"
    | "time"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "search"
    | "date"
    | "password"
    | "week"
    | "month"
    | "datetime-local";
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: red[700],
    },
  },
});

const TextField: React.FC<TextFieldProps> = ({
  name,
  type,
  label,
  mask,
  unmask = true,
  disabled,
  maxLength,
  minLength,
  inputMode,
  initialValue,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordIcon = useMemo(() => {
    return showPassword ? eyeOff : eye;
  }, [showPassword]);

  const { errors, watch, setValue, register } = useFormContext();

  useEffect(() => {
    register(name);
    if (initialValue) {
      setValue(name, initialValue);
    }
  }, [name]);

  const fieldWatched = watch(name);
  const [maskedInput, setMaskedInput] = useState(fieldWatched || "");
  useEffect(() => {
    if (!maskedInput) {
      setMaskedInput(fieldWatched);
    }
    if (!fieldWatched) {
      setMaskedInput("");
    }
  }, [fieldWatched, maskedInput]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (inputRef.current)
      inputRef.current.onchange = (e: any) => {
        if (e.target?.value) {
          setValue(
            name,
            unmask ? e.target.value.replace(/\D/g, "") : e.target.value,
            { shouldDirty: true }
          );
          if (mask) {
            setMaskedInput(e.target.value);
          }
        }
      };
  }, [inputRef.current, name, mask]);

  return (
    <>
      {mask ? (
        <ThemeProvider theme={theme}>
          <ReactInputMask
            mask={mask}
            value={maskedInput}
            disabled={disabled}
            inputMode={inputMode}
            name={name}
            onChange={(e) => {
              setMaskedInput(e.target.value);
              setValue(
                name,
                unmask ? e.target.value.replace(/\D/g, "") : e.target.value,
                { shouldDirty: true }
              );
            }}
          >
            {(inputProps: any) => (
              <MuiTextField
                fullWidth
                label={label}
                InputProps={{ disableUnderline: true }}
                style={{
                  border: "2px solid var(--ion-color-primary)",
                  borderRadius: 10,
                  backgroundColor: "white",
                  marginBottom: 16,
                  paddingLeft: 16,
                  paddingTop: 3,
                  paddingBottom: 7,
                }}
                inputProps={inputProps}
                type={type || "text"}
              />
            )}
          </ReactInputMask>
        </ThemeProvider>
      ) : (
        <IonItemOutlined>
          <IonLabel position={"floating"}>{label}</IonLabel>
          <IonInput
            name={name}
            value={fieldWatched}
            disabled={disabled}
            inputmode={inputMode}
            minlength={minLength}
            maxlength={maxLength}
            onIonChange={(e) =>
              setValue(name, e.detail.value, { shouldDirty: true })
            }
            type={
              type
                ? type === "password"
                  ? showPassword
                    ? "text"
                    : "password"
                  : type
                : "text"
            }
          />
          {type === "password" && (
            <IonButton
              style={{ marginTop: 16 }}
              fill={"clear"}
              slot={"end"}
              onClick={() => setShowPassword(!showPassword)}
            >
              <IonIcon
                slot={"icon-only"}
                style={{ fontSize: 28 }}
                icon={togglePasswordIcon}
              />
            </IonButton>
          )}
        </IonItemOutlined>
      )}

      {errors && errors[name] && (
        <IonText color={"danger"}>
          <p>{errors[name]?.message}</p>
        </IonText>
      )}
    </>
  );
};

export default TextField;
