import { Plugins } from "@capacitor/core";
import { useEffect, useState } from "react";

const { Device } = Plugins;

export function usePlatform() {
  const [platform, setPlatform] = useState<
    "ios" | "android" | "electron" | "web" | null
  >(null);
  useEffect(() => {
    Device.getInfo().then((device) => setPlatform(device.platform));
  }, []);

  return platform;
}
