import React from "react";
import { IonIcon, IonText } from "@ionic/react";
import styled from "styled-components";
import { logoFacebook, logoInstagram, mail } from "ionicons/icons";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useRootStore } from "../models/root-store/root-store.context";

const SocialLink = styled.li`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const SocialIcon = styled(IonIcon)`
  margin-bottom: -3px;
  margin-right: 2px;
  font-size: 24px;
`;

const Footer = observer(() => {
  const {
    auth: { accessToken },
  } = useRootStore();
  return (
    <footer
      style={{
        marginTop: 52,
        padding: 16,
        backgroundColor: "#fafafa",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          style={{
            width: 118,
            height: 118,
            display: "inline-block",
            marginRight: 8,
          }}
          src="/assets/images/LogotipoBeauticket.png"
          alt="Beauticket"
        />
        <div>
          <p>Com a Beauticket você fica mais bonita pagando menos</p>
          <div>
            <a
              href={`${process.env.REACT_APP_API_URL}/politica_de_privacidade.pdf`}
            >
              Política de privacidade
            </a>{" "}
            {!accessToken && (
              <>
                |{" "}
                <Link to="/boas-vindas/empresa">
                  Cadastre seu estabelecimento
                </Link>
              </>
            )}
          </div>
          <ul
            style={{
              padding: 0,
              listStyle: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SocialLink>
              <a
                href="mailto:contato@beauticket.com.br"
                aria-label={"Enviar email"}
              >
                <SocialIcon icon={mail} title={"Enviar email"} />
              </a>
            </SocialLink>
            <SocialLink>
              <a
                target={"_blank"}
                rel={"noreferrer noopener"}
                href="https://facebook.com"
                aria-label={"Facebook beauticket"}
              >
                <SocialIcon icon={logoFacebook} title={"Facebook"} />
              </a>
            </SocialLink>
            <SocialLink>
              <a
                target={"_blank"}
                rel={"noreferrer noopener"}
                href="https://instagram.com"
                aria-label={"Instagram beauticket"}
              >
                <SocialIcon icon={logoInstagram} title={"Instagram"} />
              </a>
            </SocialLink>
            <a
              href={"https://apps.apple.com/br/app/beauticket/id1544873086"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              aria-label={"Baixar na App Store"}
              title={"Baixar na App Store"}
            >
              <img
                height={40}
                src="https://linkmaker.itunes.apple.com/images/badges/pt-br/badge_appstore-lrg.svg"
                alt=""
              />
            </a>
            <a
              target={"_blank"}
              rel={"'noopener noreferrer"}
              aria-label={"Disponivel no Google Play"}
              title={"Disponivel no Google Play"}
              href={
                "https://play.google.com/store/apps/details?id=br.com.beauticket"
              }
            >
              <img
                height={58}
                src="/assets/images/google-play-badge.png"
                alt=""
              />
            </a>
          </ul>
        </div>
      </div>
      <IonText color="medium">
        <p style={{ fontSize: 13 }}>
          Desenvolvido por{" "}
          <a target={"_blank"} href="https://oniq.com.br">
            Oniq Consultoria e Tecnologia
          </a>
        </p>
      </IonText>
    </footer>
  );
});

export default Footer;
