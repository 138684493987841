import { Instance, types } from "mobx-state-tree";

export interface IAddressModel extends Instance<typeof AddressModel> {}
export const AddressModel = types.model({
  id: types.string,
  street: types.string,
  cep: types.string,
  complement: types.maybeNull(types.string),
  number: types.number,
  neighborhood: types.string,
  cityId: types.number,
});
