import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as cnpj from "cnpj";

const phoneUtil = PhoneNumberUtil.getInstance();

export const EditCompanyBasicSchema = yup.object().shape({
  logo: yup.mixed().required(),
  name: yup.string().required(),
  cnpj: yup
    .string()
    .required()
    .test("is-cnpj", "${path} não é valido", (value) => {
      return !!value && cnpj.validate(value);
    }),
  email: yup.string().required().email(),
  phone: yup
    .string()
    .required()
    .test("is-phone", "Telefone inválido", (value) =>
      phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, "BR"), "BR")
    ),
  landline: yup
    .string()
    .nullable()
    .test(
      "is-phone",
      "Telefone inválido",
      (value) =>
        !!value &&
        phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, "BR"), "BR")
    ),
  about: yup.string().required(),
  facebookUrl: yup.string().nullable().url(),
  instagram: yup.string().nullable().url(),
  website: yup.string().nullable().url(),
  banner: yup.mixed().required(),
  password: yup
    .string()
    .nullable()
    .min(6, "Senha deve ter 6 caracteres ou mais"),
  passwordConfirmation: yup
    .string()
    .nullable()
    .oneOf([yup.ref("password"), null], "Senhas não conferem"),
});
