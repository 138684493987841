import React from "react";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";

import CreateSlotTab from "./CreateSlotTab";

const CreateSlotRoutes = () => {
  return (
    <IonRouterOutlet>
      <Route exact path="/criar-tickets" component={CreateSlotTab} />
    </IonRouterOutlet>
  );
};

export default CreateSlotRoutes;
